/* eslint-disable */
import {
  Grid,
  RACButton,
  RACDatePicker,
  RACModalCard,
  RACSelect,
  RACTextbox,
  Typography,
} from '@rentacenter/racstrap';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';
import {
  DropdownValue,
  NewRuleOption,
  NewRuleRequest,
} from '../../../Interfaces/Inventory/AppConfigInterface';
import {
  convertStringToObject,
  formDropdownValues,
  getBusinessValueIcon,
  getUniqueDataByProperty,
  isMatchingObject,
  sortByProperty,
  validDatatype,
} from '../../../utils/AppconfigurationUtils';
import {
  fetchOrganizationDetails,
  manageOrganizationRule,
} from '../../../api/user';
import { PinPopup } from './PinPopup';
import DynamicPopup from './DynamicPopup';
import { booleanValues } from '../../../constants/constants';
import { ReactComponent as AddSACDays } from "../../../Icons/ItemAddition.svg";
import { ReactComponent as DeleteSACDays } from "../../../Icons/Record delete.svg";

export function AssignRule(props?: any) {
  const [showConfiramtion, setShowConfirmation] = useState<boolean>(false);
  const {
    classes,
    rulesDropdownLoader,
    category,
    group,
    rule,
    state,
    company,
    store,
    ruleConstraints,
    districtDropdown,
    regionDropdown,
    countryDropdown,
    lobDropdown,
    franchiseeUser
  } = useContext(AppConfigurationContext);
  
  const assignRuleRequest: NewRuleOption = {
    categoryDisplayName: '',
    groupDisplayName: '',
    keyDisplayName: '',
    paramDesc: '',
    hierarchyType: '',
    hierarchyValue: '',
    parentHierarchyType: '',
    parentHierarchyValue: '',
    paramName: '',
    paramValue: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment('2099-12-31').format('YYYY-MM-DD')
  };
  const hierarchyList = [
    { label: 'Select', value: '' },
    { label: 'Store', value: 'STORE' },
    { label: 'District', value: 'DISTRICT' },
    { label: 'Region', value: 'REGION' },
    { label: 'State', value: 'STATE' },
    { label: 'Company', value: 'COMPANY' },
    { label: 'Country', value: 'COUNTRY' },
    { label: 'LOB', value: 'LOB' },
  ];
  const hierarchyListFranchisee = [
    { label: 'Select', value: '' },
    { label: 'Store', value: 'STORE' },
    { label: 'District', value: 'DISTRICT' },
    { label: 'Region', value: 'REGION' },
    { label: 'State', value: 'STATE' },
    { label: 'Company', value: 'COMPANY' }
  ];
  const [paramValueError, setParamValueError] = useState<string>('');
  const [selectedRuleName, setSelectedRuleName] = useState<string>('');
  const [sacRule, setSacRule] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([{ term: '', sac: '' }]);
  const [newRuleDetails, setNewRuleDetails] = useState<NewRuleOption>(assignRuleRequest);
  const [hierarchyValueLoader, setHierarchyValueLoader] = useState<boolean>(false);
  const [parentLoader, setParentLoader] = useState<boolean>(false);
  const [savingLoader, setSavingLoader] = useState<boolean>(false);
  const [showPinPopup, setShowPinPopup] = useState<boolean>(false);
  const [showSavedpopup,setShowSavedPopup]=useState<boolean>(false);
  const [popupType,setPopupType]= useState<string>('ERROR');
  const [paramTemplate,setParamTemplate]=useState<string>('')
  const [showPopup,setShowPopup]= useState<boolean>(false);
  const [businessValueType, setBusinessValueType] = useState<string>('TEXT');
  const [categoryDropdown, setCatgeoryDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [groupDropdown, setGroupDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [ruleDropdown, setRuleDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [paramGroupId, setParamGroupId] = useState<string[]>([]);
  const [hierarchyValueDropdown, setHierarchyValueDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [parentHierarchyType, setParentHierarchyType] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [paramNameDropdown, setParamNameDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const [parentHierarchyValueDropdown, setParentHierarchyValueDropdown] = useState<DropdownValue[]>([{ label: 'Select', value: '' }]);
  const validRequest =
    newRuleDetails.categoryDisplayName != '' &&
    newRuleDetails.groupDisplayName != '' &&
    newRuleDetails.keyDisplayName != '' &&
    newRuleDetails.hierarchyType != '' &&
    newRuleDetails.hierarchyValue!=''&&
    newRuleDetails.paramValue != '' &&
    newRuleDetails.startDate != '' &&
    newRuleDetails.endDate != '' &&
    ((paramNameDropdown.length>1 && newRuleDetails.paramName != '') || !(paramNameDropdown.length>1)) &&
    ((newRuleDetails.parentHierarchyType != '' && newRuleDetails.parentHierarchyValue != '') || 
    (franchiseeUser == false && newRuleDetails.parentHierarchyType == '') || !(franchiseeUser == true && newRuleDetails.hierarchyType == 'STATE' && (newRuleDetails.parentHierarchyType == '' || 
    (newRuleDetails.parentHierarchyType != '' && newRuleDetails.parentHierarchyValue == ''))) || (franchiseeUser == true && newRuleDetails.hierarchyType != 'STATE' && newRuleDetails.parentHierarchyType == ''))

    const hierarchyLabel=(fieldType:string,type?:string)=>{
    switch(type){
      case "COMPANY":
          return 'Company';
      case 'STATE':
          return 'State';
      case 'STORE':
          return 'Store';
      case 'REGION':
          return 'Region';
      case 'DISTRICT':
          return 'District';
      case 'COUNTRY':
          return 'Country';
      case 'LOB':
          return 'LOB';
      default:
        {
          if (fieldType=='PARENT'){
                return 'Parent Hierarchy Value'
          }
              else{
                return 'Hierarchy Value'
              }
            }
        }
      }

  const buildDropdown = () => {
    try{
    let categoryFormed: DropdownValue[] = formDropdownValues(category,"displayName","displayName","single",franchiseeUser);
    categoryFormed=getUniqueDataByProperty(categoryFormed,'label');
    categoryFormed=sortByProperty(categoryFormed,'label');
    setCatgeoryDropdown(categoryFormed);
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  useEffect(() => {
    buildDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category,showPopup]);

  const handleCategoryDropdown = (e: any) => {
    try{

    setNewRuleDetails({
      ...newRuleDetails,
      categoryDisplayName: e.target.value,
      groupDisplayName: '',
      keyDisplayName: '',
      paramDesc: '',
    paramValue:'',
    paramName:''
  });
  setParamNameDropdown([{label:'Select',value:''}]);
  setParamValueError('')
    let groupFormed: DropdownValue[] = [{label:'Select',value:''}];
    const categoryId:string[]=[];
    category?.forEach((categoryDetails:any)=>{
      if(e.target.value==categoryDetails.displayName){
        categoryId.push(categoryDetails.paramCategoryId);
      }
    });

    group.forEach((groupDetails: any) => {
      if (categoryId.includes(groupDetails.paramCategoryId)) {
        groupFormed.push({
          label: groupDetails.displayName,
          value: groupDetails.displayName,
        });
      }
    });
    groupFormed=getUniqueDataByProperty(groupFormed,'label');
    groupFormed=sortByProperty(groupFormed,'label');
    setGroupDropdown(groupFormed);
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleGroupDropdown = (e: any) => {
    try{
    setNewRuleDetails({
      ...newRuleDetails,
      groupDisplayName: e.target.value,
      keyDisplayName: '',
      paramDesc: '',
      paramValue:'',
      paramName:''
    });
    setParamNameDropdown([{label:'Select',value:''}]);
    setParamValueError('');
    let ruleFormed: DropdownValue[] = [{ label: 'Select', value: '' }];
    const groupId:string[]=[];
    const categoryData=category?.filter((item:any)=>item.displayName==newRuleDetails.categoryDisplayName)
    const categoryId=categoryData?.map((item:any)=>item.paramCategoryId);

    group?.forEach((groupDetails:any)=>{
      if(groupDetails.displayName==e.target.value && categoryId.includes(groupDetails.paramCategoryId)){
        groupId.push(groupDetails.paramGroupId);
      };
    });

    setParamGroupId(groupId);

    rule.forEach((ruleDetails: any) => {
      
        if (groupId.includes(ruleDetails.paramGroupId)) {
          ruleFormed.push({
            label: ruleDetails.displayName,
            value: ruleDetails.displayName,
      })
    }
    });
    ruleFormed=getUniqueDataByProperty(ruleFormed,'label');
    ruleFormed=sortByProperty(ruleFormed,'label');
    setRuleDropdown(ruleFormed);
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleRuleDropdown = async (e: any) => {
    try{
 
    const options={...newRuleDetails};
    const selectedRule = rule?.filter((ruleInfo: any) => ruleInfo.displayName == e.target.value && paramGroupId.includes(ruleInfo.paramGroupId));
    setSelectedRuleName(selectedRule[0].paramKeyName);

    setSacRule(false);

    if(selectedRule[0].paramKeyName == 'SameAsCashDays' || selectedRule[0].paramKeyName == 'SameAsCashDaysPrinted'){
      setSacRule(true);
    }
    
    const ruleInfo=ruleConstraints[selectedRule[0]?.paramKeyId];
    options.keyDisplayName=e.target.value;
    options.paramValue= '';
    options.paramName='';
    options.paramDesc= e.target.value==''?'':ruleInfo?.desc;
    options.paramValue=e.target.value==''?'':ruleInfo?.template?ruleInfo?.template:options.paramValue;

    setBusinessValueType(ruleInfo?.datatype);
    setParamTemplate(ruleInfo?.template);

    const paramName=[{label:'Select',value:''}];
    ruleInfo?.paramName?.forEach((name:string)=>{
      paramName.push({
        label:name,
        value:name
      })
    });
    setNewRuleDetails(options);
    setParamNameDropdown(paramName);
    setParamValueError('');
    setRows([{ term: '', sac: '' }]);
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const buildParentHierarchyValue = async (
    hierarchyType: string,
    hierarchyValue: string,
    parentHierarchyType: string
  ) => {

    try{

    if (
      hierarchyType != '' &&
      hierarchyValue != '' &&
      parentHierarchyType != ''
    ) {
      setParentLoader(true);
      const parentHierarchyResponse = await fetchOrganizationDetails({
        type: parentHierarchyType,
        filterType: hierarchyType,
        filterValue: [hierarchyValue],
      });
      if (
        parentHierarchyResponse?.status == 200 &&
        parentHierarchyResponse.data?.result
      ) {
        const dropdownValue = formDropdownValues(
          parentHierarchyResponse.data.result,
          'referenceName',
          'referenceValue',
          "single"
        );
        setParentHierarchyValueDropdown(dropdownValue);
      } else {
        setParentHierarchyValueDropdown([{ label: 'Select', value: '' }]);
      }
      setParentLoader(false);
    }
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleHierarchyType = async (e: any) => {
    const companyList = company.map(({ referenceValue }: any) => referenceValue );
    try{
      setNewRuleDetails({
        ...newRuleDetails,
        hierarchyType: e.target.value,
        hierarchyValue: '',
        parentHierarchyType: '',
        parentHierarchyValue: '',
      });
    if (e.target.value != '') {
      const parentHierarchyList = [{ label: 'Select', value: '' }];
      const hierarchyValue = [{ label: 'Select', value: '' }];
      let dropdownValue: DropdownValue[] = [];
      if (e.target.value == 'STATE') {
        dropdownValue = formDropdownValues(
          state,
          'referenceValue',
          'referenceValue',
          "single"
        );
        parentHierarchyList.push({ label: 'Company', value: 'COMPANY' });
      } else if (e.target.value == 'COMPANY') {
        dropdownValue = formDropdownValues(
          company,
          'referenceName',
          'referenceValue',
          "single"
        );
      } else if (e.target.value == 'STORE') {
        dropdownValue = formDropdownValues(
          store,
          'referenceName',
          'referenceName',
          "single"
        );
      }
      else if (e.target.value == 'DISTRICT') {
        if(!franchiseeUser){
          dropdownValue = hierarchyValue
          console.log('districtDropdown', districtDropdown)
          if(districtDropdown.length){
            dropdownValue = dropdownValue.concat(districtDropdown);
          }
        }
        else{
          setHierarchyValueLoader(true);
          const distHierarchyResponse = await fetchOrganizationDetails({
            type: 'DISTRICT',
            filterType: 'COMPANY',
            filterValue: companyList,
          });
          console.log('distHierarchyResponse', distHierarchyResponse)
          if (
            distHierarchyResponse?.status == 200 &&
            distHierarchyResponse.data?.result
          ) {
            dropdownValue = formDropdownValues(
              distHierarchyResponse.data.result,
              'referenceName',
              'referenceValue',
              "single"
            );
          }
          setHierarchyValueLoader(false);
        }
      }
      else if (e.target.value == 'REGION') {
        if(!franchiseeUser){
          dropdownValue = hierarchyValue;
          if(regionDropdown.length){
            dropdownValue = dropdownValue.concat(regionDropdown);
          }
        }
        else{
          setHierarchyValueLoader(true);
          const regionHierarchyResponse = await fetchOrganizationDetails({
            type: 'REGION',
            filterType: 'COMPANY',
            filterValue: companyList,
          });
          console.log('regionHierarchyResponse', regionHierarchyResponse)
          if (
            regionHierarchyResponse?.status == 200 &&
            regionHierarchyResponse.data?.result
          ) {
            dropdownValue = formDropdownValues(
              regionHierarchyResponse.data.result,
              'referenceName',
              'referenceValue',
              "single"
            );
          }
          setHierarchyValueLoader(false);
        }
      }
      else if (e.target.value == 'LOB') {
        dropdownValue = hierarchyValue
        if(lobDropdown.length){
          dropdownValue = dropdownValue.concat(lobDropdown);
        }
      }
      else if (e.target.value == 'COUNTRY') {
        parentHierarchyList.push({ label: 'LOB', value: 'LOB' });
        dropdownValue = hierarchyValue
        if(countryDropdown.length){
          dropdownValue = dropdownValue.concat(countryDropdown);
        }
      }
      setHierarchyValueDropdown( dropdownValue );
      setParentHierarchyType(parentHierarchyList);
    }
  }catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleHierarchyValue = (e: any) => {
    try{
    setNewRuleDetails({
      ...newRuleDetails,
      hierarchyValue: e.target.value,
      parentHierarchyValue: '',
    });
    buildParentHierarchyValue(
      newRuleDetails.hierarchyType,
      e.target.value,
      newRuleDetails.parentHierarchyType
    );
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleParentHierarchyType = (e: any) => {
    try{
      if(!franchiseeUser){
        setNewRuleDetails({
          ...newRuleDetails,
          parentHierarchyType: e.target.value,
          parentHierarchyValue: '',
        });
        buildParentHierarchyValue(
          newRuleDetails.hierarchyType,
          newRuleDetails.hierarchyValue,
          e.target.value
        );
      }
      else if (e.target.value == 'COMPANY'){
        setNewRuleDetails({
          ...newRuleDetails,
          parentHierarchyType: e.target.value,
          parentHierarchyValue: '',
        });
        const dropdownValue = formDropdownValues(
          company,
          'referenceName',
          'referenceValue',
          "single"
        );
        setParentHierarchyValueDropdown(dropdownValue);
      }
      else{
        setNewRuleDetails({
          ...newRuleDetails,
          parentHierarchyType: e.target.value,
          parentHierarchyValue: '',
        });
        buildParentHierarchyValue(
          newRuleDetails.hierarchyType,
          newRuleDetails.hierarchyValue,
          e.target.value
        );
      }
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const handleParamValue=(e:any)=>{
    const options={...newRuleDetails};

    if(e.target.value==''){
      options.paramValue=''
    }
    else{
      const value=validDatatype(businessValueType,e.target.value);
      
      if(value){
        options.paramValue=value;
      }
    }

    setNewRuleDetails(options);
  }

  const handleSACParamValue=(e:any)=>{
    debugger
    const options={...newRuleDetails};

    const value = validDatatype(businessValueType,e);
      
    if(value){
      const sacValue = {
        sameascash: value
      }
      options.paramValue = JSON.stringify(sacValue);
    }

    setNewRuleDetails(options);
  }

  const isValidSac = () => {
    return rows.every(row => row.term !== '' && row.sac !== '');
  };

  const confirmationPopup = () => (
    <Grid container md={12} style={{ padding: '0rem 14px' }}>
      <Grid item md={12} style={{ textAlign: 'center', marginBottom: '1rem' }}>
        <Typography variant="h4">
          Are you sure you want to assign a rule?
        </Typography>
      </Grid>
      <Grid item md={12} style={{ textAlign: 'center', marginTop: '1rem' }}>
        <RACButton
          variant="outlined"
          color="primary"
          style={{ padding: '10px 5px', marginRight: '1rem' }}
          onClick={() => {
            setShowConfirmation(false);
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          onClick={() => {
            setShowConfirmation(false);
            setShowPinPopup(true);
          }}
        >
          Yes
        </RACButton>
      </Grid>
    </Grid>
  );

  const organizationRuleDetails = () => (
    <>
      <Grid container md={12} style={{ margin: '0.5rem 0rem' }}>
        <Grid item md={12} style={{ marginBottom: '1rem' }}>
          <Typography variant="h5">Rule</Typography>
        </Grid>
        <Grid container md={12} style={{ marginBottom: '1rem' }}>
          <Grid item md={6} style={{ paddingRight: '1rem' }}>
            <RACSelect
              required={true}
              inputLabel="Category"
              loading={rulesDropdownLoader}
              options={categoryDropdown}
              defaultValue={newRuleDetails.categoryDisplayName}
              onChange={handleCategoryDropdown}
            />
          </Grid>
          <Grid item md={6} style={{ paddingLeft: '1rem' }}>
            <RACSelect
              required={true}
              inputLabel="Group"
              isDisabled={newRuleDetails.categoryDisplayName == ''}
              loading={rulesDropdownLoader}
              options={groupDropdown}
              defaultValue={newRuleDetails.groupDisplayName}
              onChange={handleGroupDropdown}
            />
          </Grid>
        </Grid>

        <Grid container md={12} style={{ marginBottom: '1rem' }}>
          <Grid item md={6} style={{ paddingRight: '1rem' }}>
            <RACSelect
              required={true}
              inputLabel="Rule Name"
              isDisabled={newRuleDetails.groupDisplayName == ''}
              loading={rulesDropdownLoader}
              options={ruleDropdown}
              defaultValue={newRuleDetails.keyDisplayName}
              onChange={handleRuleDropdown}
            />
          </Grid>
        </Grid>

        <Grid
          container
          md={12}
          spacing={2}
          style={{
            backgroundColor: '#eff4ff',
            margin: '2px 0px',
            borderRadius: '10px',
            minHeight: '4rem',
          }}
        >
          <Grid
            item
            md={12}
            style={{
              wordWrap: 'break-word',
              overflowX: 'hidden',
              width: '300px',
            }}
          >
            <Typography>Description:&ensp; {newRuleDetails.paramDesc}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const hierarchyDetails = () => (
    <>
      <Grid container md={12} style={{ margin: '0.5rem 0rem' }}>
        <Grid item md={12} style={{ marginBottom: '1rem' }}>
          <Typography variant="h5">Hierarchy & Values</Typography>
        </Grid>
        <Grid
          container
          md={12}
          style={{
            marginBottom: '1rem',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Grid item md={6} style={{ paddingRight: '1rem' }}>
            <RACSelect
              required={true}
              inputLabel="Hierarchy Type"
              isDisabled={!(store.length && company.length && state.length)}
              options={franchiseeUser ? hierarchyListFranchisee : hierarchyList}
              defaultValue={newRuleDetails.hierarchyType}
              onChange={handleHierarchyType}
            />
          </Grid>
          <Grid item md={6} style={{ paddingLeft: '1rem' }}>
            <RACSelect
              required={true}
              inputLabel= {hierarchyLabel('CHILD',newRuleDetails.hierarchyType)}
              isDisabled={newRuleDetails.hierarchyType == ''}
              loading={hierarchyValueLoader}
              options={hierarchyValueDropdown}
              defaultValue={newRuleDetails.hierarchyValue}
              onChange={handleHierarchyValue}
            />
          </Grid>
        </Grid>

        <Grid container md={12} style={{ marginBottom: '1rem' }}>
          <Grid item md={6} style={{ paddingRight: '1rem' }}>
            <RACSelect
              inputLabel="Parent Hierarchy Type"
              isDisabled={
                !(
                  newRuleDetails.hierarchyType == 'STATE' ||
                  newRuleDetails.hierarchyType == 'COUNTRY'
                ) || hierarchyValueLoader
              }
              required={ (franchiseeUser && newRuleDetails.hierarchyType == 'STATE') ? true : false}
              options={parentHierarchyType}
              defaultValue={newRuleDetails.parentHierarchyType}
              onChange={handleParentHierarchyType}
            />
          </Grid>
          <Grid item md={6} style={{ paddingLeft: '1rem' }}>
            <RACSelect
              inputLabel= {hierarchyLabel('PARENT',newRuleDetails.parentHierarchyType)}
              isDisabled={newRuleDetails.hierarchyValue&&newRuleDetails.hierarchyType&&
                newRuleDetails.parentHierarchyType ? false : true}
              loading={parentLoader}
              options={parentHierarchyValueDropdown}
              defaultValue={newRuleDetails.parentHierarchyValue}
              onChange={(e: any) => {
                setNewRuleDetails({
                  ...newRuleDetails,
                  parentHierarchyValue: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const businessValue = () => {

    if(selectedRuleName == 'SameAsCashDays' || selectedRuleName == 'SameAsCashDaysPrinted'){

      const handleTermChange = (index: number, value: number) => {
        const newRows = [...rows];
        newRows[index].term = value;
        setRows(newRows);
        handleSACParamValue(rows)
      };
    
      const handleSACChange = (index: number, value: number) => {
        const newRows = [...rows];
        newRows[index].sac = value;
        setRows(newRows);
        handleSACParamValue(rows)
      };

      const handleAddClick = () => {

        setRows([...rows, { term: '', sac: '' }]);
      };
    
      const handleRemoveClick = (index: number) => {
        if (rows.length > 1) {
          const newRows = rows.filter((_, i) => i !== index)
          console.log('newRows', newRows)
          setRows(newRows);
          handleSACParamValue(newRows)
        }
      };

      const renderRows = () => {
        return rows.map((row, index) => (
          <React.Fragment key={index}>
            <Grid item md={6} style={{ marginBottom: '1rem', display: 'flex' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomLeftRadius: "25%",
                  borderTopLeftRadius: "25%",
                  width: "35px",
                  backgroundColor: "rgb(220,223,228)",
                  border: "1px solid lightgrey",
                }}
              >
                {getBusinessValueIcon('TERM')}
              </div>
              <RACTextbox
                value={row.term}
                className={classes.indexStyle}
                type='number'
                OnChange={(e) => handleTermChange(index, e.target.value)}
                placeHolder={'Enter the Term'}
              />
            </Grid>
    
            <Grid item md={6} style={{ marginBottom: '1rem', display: 'flex', paddingLeft: '1rem' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomLeftRadius: "25%",
                  borderTopLeftRadius: "25%",
                  width: "35px",
                  backgroundColor: "rgb(220,223,228)",
                  border: "1px solid lightgrey",
                }}
              >
                {getBusinessValueIcon('DAYS')}
              </div>
              <RACTextbox
                value={row.sac}
                className={classes.indexStyleSACDays}
                type='number'
                OnChange={(e) => handleSACChange(index, e.target.value)}
                placeHolder={'Enter the SAC Days'}
              />
              {index > 0 && (
                <Grid style={{ display: 'flex', paddingLeft: '1rem' }}>
                  <Typography style={{ display: "flex", paddingLeft: '10px', marginTop: '10px' }}>
                    <DeleteSACDays  style={{cursor:'pointer'}} onClick={() => handleRemoveClick(index)} />
                  </Typography>
                </Grid>
              )}
              {index == 0 && (
                <Grid style={{ display: 'flex', paddingLeft: '1rem' }}>
                    <AddSACDays style={ isValidSac()? {cursor:'pointer'} : {}}
                      onClick={() => { 
                        isValidSac() ? handleAddClick(): {}
                        }
                      }
                    />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ));
      };

      return (
        <>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography>Term <span style={{ color: 'red' }}>*</span></Typography>
            </Grid>
            <Grid item md={6} style={{paddingLeft: '1rem'}}>
              <Typography>SAC <span style={{ color: 'red' }}>*</span></Typography>
            </Grid>
          </Grid>
    
          <Grid container>
            {renderRows()}
          </Grid>
        </>
      );
    }
    else{
      return (
        <>
          <Grid container md={12}>
         
            {paramNameDropdown.length>1?paramNameHeading():<></>}
  
            <Grid item md={6} style={paramNameDropdown.length>1?{paddingLeft: '1rem'}:{}}>
            <Typography>
              Business Value <span style={{ color: 'red'  }}>*</span>
            </Typography>
            </Grid>
            
          </Grid>
  
          <Grid container md={12}>
          
            {paramNameDropdown.length>1?paramNameInputField():<></>}
  
            <Grid item md={6} style={paramNameDropdown.length>1?{ marginBottom: '1rem',display: 'flex',paddingLeft:'1rem',flexWrap:'wrap' }:{ marginBottom: '1rem',display: 'flex' }}>
              <div
                  style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: "25%",
              borderTopLeftRadius: "25%",
              width: paramNameDropdown.length>1?"33px":"35px",
              backgroundColor: "rgb(220,223,228)",
              border: "1px solid lightgrey",
            }}
          >
            {getBusinessValueIcon(businessValueType)}
          </div>
              <RACTextbox disabled={newRuleDetails.keyDisplayName==''} value={newRuleDetails.paramValue} className={classes.indexStyle} OnChange={handleParamValue} />
              
            </Grid>
          </Grid>
          
          <Grid container md={12}>
          {paramNameDropdown.length>1?<Grid item md={6}/>:<></>}
            <Grid item md={6}>
            <span style={{flexBasis: '100%', width: '100%', display: 'block',color:'red',marginTop:'5px'}}>{paramValueError}</span>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const ruleDuration = () => (
    <>
      <Grid
        container
        md={12}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item md={12} style={{ marginBottom: '1rem' }}>
          <Typography variant="h5">Date Range</Typography>
        </Grid>
        <Grid
          item
          md={6}
          style={{ marginBottom: '1rem', paddingRight: '1rem' }}
        >
          <RACDatePicker
            label={'Start Date'}
            required={true}
            value={newRuleDetails.startDate}
            inputProps={{
              min: moment().format('YYYY-MM-DD'),
            }}
            onChange={(e: any) => {
              setNewRuleDetails({ ...newRuleDetails, startDate: e });
            }}
          />
        </Grid>
        <Grid item md={6} style={{ marginBottom: '1rem', paddingLeft: '1rem' }}>
          <RACDatePicker
            label={'End Date'}
            required={true}
            value={newRuleDetails.endDate}
            inputProps={{
              min: newRuleDetails.startDate,
            }}
            onChange={(e: any) => {
              setNewRuleDetails({ ...newRuleDetails, endDate: e });
            }}
          />
        </Grid>
      </Grid>
    </>
  );

  const formPayload = () => {
    const request: NewRuleRequest = {
      paramCategoryName: '',
      paramGroupName: '',
      paramKeyName: '',
      hierarchyType: newRuleDetails.hierarchyType,
      hierarchyValue: newRuleDetails.hierarchyValue,
      parentHierarchyType: newRuleDetails.parentHierarchyType,
      parentHierarchyValue: newRuleDetails.parentHierarchyValue,
      paramName: newRuleDetails.paramName,
      paramValue: newRuleDetails.paramValue,
      startDate: newRuleDetails.startDate,
      endDate: newRuleDetails.endDate,
      action: 'INSERT',
    };

    const categoryData =category.filter((categoryInfo:any)=>categoryInfo.displayName==newRuleDetails.categoryDisplayName)
    const groupData=group.filter((groupItem:any)=>groupItem.displayName==newRuleDetails.groupDisplayName);
    const keyData=rule.filter((keyItem:any)=>keyItem.displayName==newRuleDetails.keyDisplayName);
        
    categoryData.forEach((categoryDetails:any)=>{
      groupData.forEach((groupDetails:any)=>{
        keyData.forEach((keyDetails:any)=>{
          if(categoryDetails.paramCategoryId==groupDetails.paramCategoryId&&keyDetails.paramGroupId==groupDetails.paramGroupId){
            request.paramCategoryName = categoryDetails.paramCategoryName
            request.paramGroupName = groupDetails.paramGroupName;
            request.paramKeyName = keyDetails.paramKeyName;
          }
        })
      })
    })
    
    
    return request;
  };

  const validateBusinessValue = () => {
    try{
      const time=/^(1[0-2]|0?[1-9]):([0-5][0-9])\s(AM|PM)$/;
    let error=''
    if (businessValueType == 'JSON' || businessValueType == 'OBJECT') {
      const selectedRule = rule?.filter((ruleInfo: any) => ruleInfo.displayName==newRuleDetails.keyDisplayName);
      const isValidValue = isMatchingObject(
        convertStringToObject(ruleConstraints[selectedRule[0].paramKeyId].template),
        convertStringToObject(newRuleDetails.paramValue)
      );

      if (!isValidValue) {
        error='Please provide an appropriate Business Value';
      }
      else {
        setShowConfirmation(true);
      }
      
    } 
    else if(businessValueType=='BOOLEAN'&&!booleanValues.includes(newRuleDetails.paramValue)){
      error='Please provide an appropriate Business Value';
    }
    else if(businessValueType=='TIME'&&!time.test(newRuleDetails.paramValue)){
      error='Please provide an appropriate Business Value';
    }
    else{
      setShowConfirmation(true);
    }
    setParamValueError(error);
  }
  catch (error: any) {
    setPopupType('ERROR');
      setShowPopup(true);
  }
  };

  const assignRule = async () => {
    try {
      setSavingLoader(true);
      const payload: NewRuleRequest = await formPayload();

      const insertedResponse = await manageOrganizationRule(payload);
      
      if (insertedResponse && insertedResponse.status == 200) {
        setShowSavedPopup(true);

      } else if (insertedResponse && insertedResponse.status == 400) {
        
        setPopupType('BADREQUEST');
        setShowPopup(true);
      } else {
        setPopupType('ERROR');
        setShowPopup(true);
      }
      setSavingLoader(false);
    } catch (error: any) {
      setPopupType('ERROR');
        setShowPopup(true);
    }
  };

  const actionButtons = () => (
    <>
      <Grid
        container
        md={12}
        style={{ position: 'absolute', bottom: '0',backgroundColor:'white',padding:'1.5rem',width:'93%' }}
      >
        <Grid item md={12}>
          <RACButton
            variant="contained"
            color="primary"
            style={{ float: 'right' }}
            disabled={(sacRule && !isValidSac())||!validRequest||savingLoader}
            loading={savingLoader}
            onClick={() => {
              validateBusinessValue();
            }}
          >
            Save
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            style={{
              float: 'right',
              padding: '11px 25px',
              marginRight: '16px',
            }}
            onClick={() => {
              props?.closePopupFunction();
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    </>
  );

  const assignRulePopup = () => (
    <Grid container md={12} style={{ padding: '0rem 14px' }}>
      {organizationRuleDetails()}
      {hierarchyDetails()}
      {businessValue()}
      {ruleDuration()}
      <Grid container md={12} style={{margin:'3rem'}}/>
      {actionButtons()}
    </Grid>
  );

  const paramNameHeading=()=>(
    <Grid item md={6}>
    <Typography>
      Param Name <span style={{ color: 'red' }}>*</span>
    </Typography>
    </Grid>
  );

  const paramNameInputField=()=>(
  <Grid item md={6} style={{ marginBottom: '1rem',display: 'flex',paddingRight:'1rem'  }}>
            <RACSelect isDisabled={paramNameDropdown.length==1} options={paramNameDropdown} defaultValue={newRuleDetails.paramName} onChange={(e:any)=>{
              setNewRuleDetails({...newRuleDetails,paramName:e.target.value})
            }} />
          </Grid>
  );

  const closeAssignRule=()=>{
    props?.closePopupFunction(false)
  }
  return (
    <>
      {showConfiramtion ? (
        <RACModalCard
          maxWidth={'xs'}
          borderRadius="15px !important"
          isOpen={showConfiramtion}
          closeIcon={true}
          onClose={() => {
            setShowConfirmation(false);
          }}
          //eslint-disable-next-line react/no-children-prop
          children={confirmationPopup()}
        />
      ) : showPinPopup ? (
        <PinPopup
          onCompleteFunction={assignRule}
          closePopupFunction={setShowPinPopup}
        />
      ) : showSavedpopup?<DynamicPopup type='SAVED' closePopupFunction={setShowSavedPopup} onCloseFunction={closeAssignRule}/>:
      showPopup?<DynamicPopup type={popupType} closePopupFunction={setShowPopup}/>:
      (
        <RACModalCard
          maxWidth={'sm'}
          borderRadius="15px !important"
          isOpen={true}
          className={classes.fixPopup}
          closeIcon={true}
          onClose={() => {
            props?.closePopupFunction();
          }}
          title="Assign Rule"
          //eslint-disable-next-line react/no-children-prop
          children={assignRulePopup()}
        />
      )}
    </>
  );
}
