import { useEffect, useState } from 'react';
import {
  PaymentHistory,
  WorkHistory,
} from '../../../constants/customerDetailsInterface';
import {
  formPaymentHistoryToWorkHistoryData,
  handleWorkHistoryDetails,
} from './CustomerDetailsHelperFunc';

export function getWorkHistoryDetails(
  workHistory: any,
  setWorkHistoryTableBody: any,
  setLoader: any,
  setResponseStatus: any,
  workHistoryTableBody: any
) {
  if (workHistory && workHistory.status == 200) {
    handleWorkHistoryDetails(
      workHistory,
      setResponseStatus,
      setWorkHistoryTableBody,
      workHistoryTableBody
    );
  }
}

export const workHistoryDateStringToDate = (
  dateString: string
): Date | null => {
  const matchedDate = /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}) (AM|PM)/.exec(
    dateString
  );
  if (!matchedDate) {
    // dateString does not match the format in the regex above
    return null;
  }

  // Delete the full match from the array, leaving only matched groups.
  matchedDate.splice(0, 1);

  const [month, day, year, hour, minute, amPm] = matchedDate;
  const hourOffset = amPm === 'AM' ? 0 : 12;

  return new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    // Modulo 12 because 12 AM is 0:00 and 12 PM is 12:00
    (Number(hour) % 12) + hourOffset,
    Number(minute)
  );
};

const workHistoryEntryTimestamp = (entry: WorkHistory): number =>
  (entry.activityDate &&
    workHistoryDateStringToDate(entry.activityDate)?.valueOf()) ||
  0;

const workHistoryEntryComparator = (a: WorkHistory, b: WorkHistory): number =>
  workHistoryEntryTimestamp(a) - workHistoryEntryTimestamp(b);

// Maintains a sorted array of the current worked history and payment history.
// Allows changing the sort direction.
export const useWorkHistoryTableBody = (
  workHistory: WorkHistory[],
  paymentHistory: PaymentHistory[],
  sortDirection: 'asc' | 'desc'
): WorkHistory[] => {
  const [workHistoryTableBody, setWorkHistoryTableBody] = useState<
    WorkHistory[]
  >([]);

  useEffect(() => {
    const paymentHistoryAsWorkHistory =
      formPaymentHistoryToWorkHistoryData(paymentHistory);
    const tableBody = [...workHistory, ...paymentHistoryAsWorkHistory];
    tableBody.sort(
      sortDirection === 'asc'
        ? workHistoryEntryComparator
        : (a, b) => -workHistoryEntryComparator(a, b)
    );
    setWorkHistoryTableBody(tableBody);
  }, [paymentHistory, workHistory, sortDirection]);

  return workHistoryTableBody;
};
