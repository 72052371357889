/* eslint-disable */
import {
  Grid,
  RACButton,
  RACTableCell,
  RACTableRow,
  RACTextbox,
  RACTooltip,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AccordionClosed } from '../../../assets/images/right accordion.svg';
import { ReactComponent as AccordionOpened } from '../../../assets/images/down accordions.svg';
import React, { useContext, useEffect, useState } from 'react';
import { AppConfigurationStyles } from '../../../JsStyles/AppConfigurationStyles';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';
import { truncString } from '../../../utils/InventoryUtils';
import { getBusinessValueIcon } from '../../../utils/AppconfigurationUtils';
import { EditRule } from './EditRule';

export function AccordionItem(props?: any) {
  const classes = AppConfigurationStyles();
  const [openAccordion, setOpenAccodion] = useState<boolean>(false);
  const [rulesDetails, setRulesDetails] = useState<any>({});
  const [showEditRule, setShowEditRule] = useState<boolean>(false);
  const { viewMode, franchiseeUser, accordionCount, setAccordionCount,category,group, rule,ruleConstraints } = useContext(
    AppConfigurationContext
  );
  const { openGlobal, closeGlobal, organizationRule, index } = props;
  useEffect(() => {
    if (closeGlobal && !openGlobal) {
      setOpenAccodion(false);
    } else if (openGlobal || index == 0) {
      setOpenAccodion(true);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGlobal, closeGlobal]);

  return (
    <>
      {showEditRule ? (
        <EditRule
          closePopupFunction={setShowEditRule}
          ruleInfo={rulesDetails}
        />
      ) : (
        <></>
      )}
      <RACTableRow className={classes.whiteBackground} onClick={() => {
        setOpenAccodion(!openAccordion);
        openAccordion ? setAccordionCount(accordionCount - 1) : setAccordionCount(accordionCount + 1);
      }}>
        <RACTableCell
          colSpan={7}
          className={classes.alignCenter}
        >
          
          {openAccordion ? (
            <AccordionOpened
              className={classes.accodrionStyle}
              onClick={() => {
                setOpenAccodion(false);
                setAccordionCount(accordionCount - 1);
              }}
            />
          ) : (
            <AccordionClosed
              className={classes.accodrionStyle}
              onClick={() => {
                setOpenAccodion(true);
                setAccordionCount(accordionCount + 1);
              }}
            />
          )}
          <Typography>
            &ensp; {organizationRule.categoryMasterDisplayName}
          </Typography>

        </RACTableCell>
      </RACTableRow>

      {openAccordion
        ? organizationRule?.paramCategoryDetails?.map(
          (categoryInfo: any, index: number) => {
            return (
              <>
                {categoryInfo.ruleDetails?.map((ruleInfo: any, ruleIndex: number) => {
                  return (
                    <>
                      <RACTableRow
                        key={ruleIndex}
                        style={{
                          backgroundColor: '#eff4ff',
                        }}
                      >
                        <RACTableCell
                          style={
                            ruleIndex == 0 &&
                              ruleIndex == categoryInfo?.ruleDetails?.length - 1
                              ? {
                                borderBottomLeftRadius: '7px',
                                borderTopLeftRadius: '7px',
                              }
                              : ruleIndex == 0
                                ? {
                                  borderTopLeftRadius: '7px',
                                }
                                : ruleIndex == categoryInfo?.ruleDetails?.length - 1
                                  ? {
                                    borderBottomLeftRadius: '7px',
                                  }
                                  : {}
                          }
                        >
                          <RACTooltip
                            placement={'top-start'}
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              backgroundColor: 'white !important',
                              zIndex: 10,
                            }}
                            className={classes.toooltipStyle}
                            title={
                              <>
                                <Grid>
                                  <Typography>
                                    {categoryInfo.paramCategoryName}
                                  </Typography>
                                </Grid>
                              </>
                            }
                          >
                            <Typography style={{ color: 'black' }}>
                              {organizationRule.categoryMasterDisplayName ==
                                'Feature' || !categoryInfo?.categoryDisplayName
                                ? truncString(
                                  organizationRule.categoryMasterDisplayName,
                                  28,
                                  '...'
                                )
                                : categoryInfo?.categoryDisplayName
                                  ? truncString(
                                    categoryInfo?.categoryDisplayName,
                                    28,
                                    '...'
                                  )
                                  : '-'}
                            </Typography>
                          </RACTooltip>
                        </RACTableCell>

                        <RACTableCell>
                          <RACTooltip
                            placement={'top-start'}
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              backgroundColor: 'white !important',
                              zIndex: 10,
                            }}
                            className={classes.toooltipStyle}
                            title={
                              <>
                                <Grid>
                                  <Typography>
                                    {ruleInfo.paramGroupName}
                                  </Typography>
                                </Grid>
                              </>
                            }
                          >
                            <Typography style={{ color: '#505779' }}>
                              {!ruleInfo?.groupDisplayName
                                ? truncString(ruleInfo.paramGroupName, 20, '...')
                                : ruleInfo?.groupDisplayName
                                  ? truncString(
                                    ruleInfo?.groupDisplayName,
                                    20,
                                    '...'
                                  )
                                  : '-'}
                            </Typography>
                          </RACTooltip>
                        </RACTableCell>

                        <RACTableCell>
                          <RACTooltip
                            placement={'top-start'}
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              backgroundColor: 'white !important',
                              zIndex: 10,
                            }}
                            className={classes.toooltipStyle}
                            title={
                              <>
                                <Grid>
                                  <Typography>{ruleInfo.paramKeyName}</Typography>
                                </Grid>
                              </>
                            }
                          >
                            <Typography style={{ color: '#505779' }}>
                              {!ruleInfo?.keyDisplayName
                                ? truncString(ruleInfo.paramKeyName, 37, '...')
                                : ruleInfo?.keyDisplayName
                                  ? truncString(ruleInfo?.keyDisplayName, 37, '...')
                                  : '-'}
                            </Typography>
                          </RACTooltip>
                        </RACTableCell>

                        <RACTableCell>
                          <Typography style={{ color: '#505779' }}>
                            {ruleInfo?.hierarchyType ? ruleInfo?.hierarchyType : '-'}
                          </Typography>
                        </RACTableCell>

                        <RACTableCell>
                          <Typography style={{ color: '#505779' }}>
                            {ruleInfo?.hierarchyValue
                              ? truncString(ruleInfo?.hierarchyValue, 24, '...')
                              : '-'}
                          </Typography>
                        </RACTableCell>

                        {ruleInfo?.paramKeyName == 'SameAsCashDays' || ruleInfo?.paramKeyName == 'SameAsCashDaysPrinted' ?
                          <RACTableCell style={{minWidth: '285px'}}>
                            <Grid style={{ display: 'flex' }}>
                              <>
                                <Grid style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderBottomLeftRadius: "25%",
                                      borderTopLeftRadius: "25%",
                                      width: "35px",
                                      backgroundColor: "rgb(220,223,228)",
                                      border: "1px solid lightgrey",
                                    }}
                                  >
                                    {getBusinessValueIcon('TERM')}
                                  </div>
                                  <div >
                                    <RACTextbox
                                      className = {classes.termAccTextBox}
                                      value={
                                        JSON.parse(ruleInfo?.paramValue).sameascash[0].term
                                      }
                                      disabled
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderBottomLeftRadius: "25%",
                                      borderTopLeftRadius: "25%",
                                      width: "35px",
                                      backgroundColor: "rgb(220,223,228)",
                                      border: "1px solid lightgrey",
                                      marginLeft: '-45px'
                                    }}
                                  >
                                    {getBusinessValueIcon('DAYS')}
                                  </div>
                                  <div className = {classes.sacAccTextBoxDiv}>
                                    <RACTextbox
                                      className = {classes.sacAccTextBox}
                                      value={
                                        JSON.parse(ruleInfo?.paramValue).sameascash[0].sac
                                      }
                                      disabled
                                    />
                                  </div>
                                  {JSON.parse(ruleInfo?.paramValue)?.sameascash?.length > 1 ? <>
                                    <span className={classes.sacLengthValue}>{`+${JSON.parse(ruleInfo?.paramValue).sameascash?.length - 1}`}</span>
                                  </> : <></>}
                                </Grid>
                              </>
                            </Grid>
                          </RACTableCell>
                          : <RACTableCell>
                            <Grid style={{ display: 'flex' }}>
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderBottomLeftRadius: '25%',
                                      borderTopLeftRadius: '25%',
                                      width: '55px',
                                      backgroundColor: 'rgb(220,223,228)',
                                      border: '1px solid lightgrey',
                                    }}
                                  >
                                    {getBusinessValueIcon(ruleInfo.paramValueDatatype)}
                                  </div>
                                  <RACTextbox
                                    className={classes.businessValue}
                                    value={ruleInfo.paramValueMultiplier != 'OTHER' ? Number(ruleInfo.paramValueMultiplier) * Number(ruleInfo.paramValue) : ruleInfo.paramValue}
                                    disabled
                                  />
                                </>
                            </Grid>
                          </RACTableCell>
                        }

                        <RACTableCell
                          style={
                            ruleIndex == 0 &&
                              ruleIndex == categoryInfo?.ruleDetails?.length - 1
                              ? {
                                textAlign: 'center',
                                borderBottomRightRadius: '7px',
                                borderTopRightRadius: '7px',
                              }
                              : ruleIndex == 0
                                ? {
                                  textAlign: 'center',
                                  borderTopRightRadius: '7px',
                                }
                                : ruleIndex == categoryInfo?.ruleDetails?.length - 1
                                  ? {
                                    textAlign: 'center',
                                    borderBottomRightRadius: '7px',
                                  }
                                  : { textAlign: 'center' }
                          }
                        >
                          {(viewMode || (franchiseeUser && ['LOB', 'COUNTRY', 'LOB+COUNTRY', 'STATE'].includes(ruleInfo?.hierarchyType) == true) || (franchiseeUser && (organizationRule.categoryMasterDisplayName.toLowerCase() == 'legal'))) ? (
                            <RACButton
                              variant="outlined"
                              size="small"
                              color="primary"
                              style={{ padding: '5px 10px' }}
                              className={classes.actionButton}
                              onClick={() => {
                                const response = { ...ruleInfo };
                                response.categoryMasterDisplayName =
                                  organizationRule.categoryMasterDisplayName;
                                response.paramCategoryName =
                                  categoryInfo.paramCategoryName;
                                response.categoryDisplayName =
                                  categoryInfo?.categoryDisplayName;
                                response.paramCategoryMasterName =
                                  organizationRule.paramCategoryMasterName;
                                response.startDate = (response.startDate).split('T')[0]
                                response.endDate = (response.endDate).split('T')[0]
                                setRulesDetails(response);
                                setShowEditRule(true);
                              }}
                            >
                              View
                            </RACButton>
                          ) : (
                            <RACButton
                              variant="outlined"
                              size="small"
                              color="primary"
                              style={{ padding: '5px 10px' }}
                              className={classes.actionButton}
                              onClick={() => {


                                const response = { ...ruleInfo };
                                response.categoryMasterDisplayName =
                                  organizationRule.categoryMasterDisplayName;
                                response.paramCategoryName =
                                  categoryInfo.paramCategoryName;
                                response.categoryDisplayName =
                                  categoryInfo?.categoryDisplayName;
                                response.paramCategoryMasterName =
                                  organizationRule.paramCategoryMasterName;

                                const chosenCategory = category.find(
                                  (categoryDetails: any) =>
                                    categoryDetails.paramCategoryName == categoryInfo.paramCategoryName
                                );

                                const chosenGroup = group.find(
                                  (groupDetails: any) =>
                                    groupDetails.paramGroupName == response.paramGroupName && chosenCategory.paramCategoryId == groupDetails.paramCategoryId
                                );

                                const chosenRule = rule.find(
                                  (ruleDetails: any) =>
                                    ruleDetails.paramKeyName == response.paramKeyName &&
                                    chosenGroup.paramGroupId == ruleDetails.paramGroupId
                                );

                                response.paramKeyId = chosenRule.paramKeyId;

                                setRulesDetails(response);
                                setShowEditRule(true);
                              }}
                            >
                              Edit
                            </RACButton>
                          )}
                        </RACTableCell>
                      </RACTableRow>
                    </>
                  );
                })}

                {organizationRule?.paramCategoryDetails?.length - 1 ==
                  index ? (
                  <></>
                ) : (
                  <RACTableCell />
                )}
              </>
            );
          }
        )
        : null}
    </>
  );
}
