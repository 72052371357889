import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as DetailsIcon } from './../../emilyIcons/More info.svg';
import { ReactComponent as ToolTipIcon } from './../../emilyIcons/Emily Icons/more.svg';
import { ReactComponent as AlertIcon } from './../../emilyIcons/no-records-found.svg';

import {
  Grid,
  Typography,
  RACButton,
  RACModalCard,
  CircularProgress,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTabs,
  RACSelect,
} from '@rentacenter/racstrap';

import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import {
  customerDetails,
  Vehicle,
  PersonalReference,
  LandlordReference,
  EmployerReference,
  LegalDetails,
  PaymentHistory,
  WorkHistory,
  TabStatus,
  Agreement,
} from '../../constants/customerDetailsInterface';
import AgreementsTab from './CustomerDetailsTabs/AgreementsTab';
import ReferenceTab from './CustomerDetailsTabs/ReferenceTab';
import EmploymentTab from './CustomerDetailsTabs/EmploymentTab';
import VehicleInfoTab from './CustomerDetailsTabs/VehicleInfoTab';
import PaymentHistoryTab from './CustomerDetailsTabs/PaymentHistoryTab';
import WorkHistoryTab from './CustomerDetailsTabs/WorkHistoryTab';
import ResidenceTab from './CustomerDetailsTabs/ResidenceTab';
import PaymentOptionTab from './CustomerDetailsTabs/PaymentOptionTab';
import {
  formateLegalHoldDropDown,
  formateTaxExemptDropDown,
  getLegalHoldCustomerStatus,
  handleCustomerData,
  handleEmploymentDetails,
  handleReferenceDetails,
  handleResidenceDetails,
  handleTaxExemptDetails,
  handleVehicleDetails,
  manageLegalHold,
} from './CustomerDependencies/CustomerDetailsHelperFunc';
import {
  getCustomerDetails,
  getLegalCustomerData,
} from './CustomerDependencies/MakeApiCall';
import {
  activeAgreementData,
  inActiveAgreementData,
} from './CustomerDependencies/CustomerAgreementHelper';
import { getPaymentHistoryData } from './CustomerDependencies/PaymentHelper';
import {
  getWorkHistoryDetails,
  useWorkHistoryTableBody,
} from './CustomerDependencies/WorkHistoryHelper';
import { getLegalData } from './CustomerDependencies/LegalDetailsHelper';
import {
  employmentModal,
  showLegalDetailsPopup,
  showPaymentHistoryPopup,
  showResidenceDetailsPopup,
  showWorkHistoryPopup,
  vehicleModal,
} from './CustomerDependencies/PopUpDetails';
import {
  legalTableHeading,
  paymentOptionTableBodies,
  phonePopupDetailsHeading,
} from '../../constants/CustomerConstants';
import { CustomerContext } from './CustomerDependencies/CustomerContext';
import TaxExamptTab from './CustomerDetailsTabs/TaxExamptTab';
import { Loader } from './CommonCompo/Loader';
import { dropdown } from '../../constants/customerSearchInterface';
import { handleOnChange } from './CustomerDependencies/TaxExemptHelper';
import { getFormatedDate, yearToDateFormater } from '../../utils/utils';
import { getLegalHoldReasons } from '../Agreement/commonFunctions';

export default function CustomerDetails() {
  const {
    loader,
    setLoader,
    errorMessage,
    setErrorMessage,
    errorMessagePopup,
    setErrorMessagePopup,
    classes,
    user,
    setUser,
    setTaxExamptTableBody,
    setTaxExemptDenialDropDown,
    setlegalHoldStatusReason,
    legalHoldStatusReason,
    legalUnHoldStatusReason,
    setlegalUnHoldStatusReason,
    pageLoader,
    toggleObject,
  } = useContext(CustomerContext);
  const [paymentOffset, setPaymentOffet] = useState<number>(10);
  const [paymentHasMore, setPaymentHasMore] = useState<boolean>(true);
  const [paymentFetchDataLoader, setPaymentFetchDataLoader] =
    useState<boolean>(false);
  const [workHistoryOffset, setWorkHistoryOffet] = useState<number>(10);
  const [workHistoryHasMore, setWorkHistoryHasMore] = useState<boolean>(true);
  const [workHistoryFetchDataLoader, setWorkHistoryFetchDataLoader] =
    useState<boolean>(false);
  const [loaderCardMessage, setLoaderCardMessage] = useState<string>('');
  const [customerDetails, setCustomerDetails] = useState<customerDetails>({
    status: 200,
    name: '-',
    coCustomerName: '-',
    coCustomerId: 0,
    creationDate: '-',
    contact: {
      primaryAddress: '-',
      mailingAddress: '-',
      phone: '-',
      emailAddress: '-',
      category: '',
    },
    personal: {
      dateOfBirth: '-',
      socialSecurity: '-',
      govIdType: '-',
      govId: '-',
      issuedState: '-',
    },
    preferences: {
      preferredLanguage: '-',
      paymentRemainderCalls: '-',
      preferredMethodOfContact: '-',
      phoneSolicitation: '-',
      mailSolicitation: '-',
    },
  });

  // legal
  const [legalTableBody, setLegalTableBody] = useState<LegalDetails[]>([
    {
      effectiveDate: '-',
      holdReason: '-',
      bankruptacyChapter: '-',
      closedDate: '-',
      closedReason: '-',
      createdBy: '-',
      createdDate: '-',
      closedBy: '-',
      lastModifiedDate: '-',
    },
  ]);

  // paymentHis
  const [paymentHistoryTableBody, setPaymentHistoryTableBody] = useState<
    PaymentHistory[]
  >([
    {
      receiptId: '-',
      store: '-',
      paymentOrigin: '-',
      tenderType: '-',
      daysExt: '-',
      daysLate: '-',
      datePaid: '-',
      nextDueDate: '-',
      actualTerm: '-',
      rentPaid: '-',
      closeDate: '-',
      agreementStatusReason: '-',
      agreementId: '-',
      oldDueDate: '-',
      netRent: '-',
      clubAmount: '-',
      ldw: '-',
      lateFee: '-',
      discount: '-',
      other: '-',
      tax: '-',
      totalPayment: '-',
      nsf: '-',
      cc4Digit: '-',
    },
  ]);
  const [paymentHistoryModel, setPaymentHistoryModel] =
    useState<boolean>(false);

  //paymentOpt
  const [paymentOptionTableBody, setPaymentOptionTableBody] = useState<any>(
    paymentOptionTableBodies
  );

  // workHistory
  const [workHistoryTableBody, setWorkHistoryTableBody] = useState<
    WorkHistory[]
  >([]);
  const [workHistoryModel, setWorkHistoryModel] = useState<boolean>(false);
  // fullWorkHistoryTableBody combines and sorts work history and payment history
  const fullWorkHistoryTableBody = useWorkHistoryTableBody(
    workHistoryTableBody,
    paymentHistoryTableBody,
    toggleObject.workHistoryDate ? 'asc' : 'desc'
  );

  // vehicle
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [vehicleTableBody, setVehicleTableBody] = useState<Vehicle[]>([
    {
      utilities: '-',
      vehicleMake: '-',
      vehicleModal: '-',
      vehicleColor: '-',
      vehicleTagNumber: '-',
      vehRegState: '-',
      phoneInWhose: '-',
      vehFinancedFrom: '-',
      autoPayment: '-',
    },
  ]);

  // agr
  const [activeAgreementsBody, setActiveAgreements] = useState<Agreement[]>([
    {
      agreementNumber: '-',
      storeNumber: '-',
      route: '-',
      customers: [],
      agreementDescription: '-',
      agreementOpenDate: '-',
      currentDueDate: '-',
      currentDayslate: '-',
      actualRate: '-',
      actualTerm: '-',
      rentPaid: '-',
      closeDate: '-',
      agreementStatusReason: '-',
      agreementId: '-',
      autoPay: false,
    },
  ]);
  const [inActiveAgreementsBody, setInactiveAgreements] = useState<Agreement[]>(
    [
      {
        agreementNumber: '-',
        storeNumber: '-',
        route: '-',
        customers: [],
        agreementDescription: '-',
        agreementOpenDate: '-',
        currentDueDate: '-',
        currentDayslate: '-',
        actualRate: '-',
        actualTerm: '-',
        rentPaid: '-',
        closeDate: '-',
        agreementStatusReason: '-',
        agreementId: '-',
        autoPay: false,
      },
    ]
  );

  const [phoneModal, setPhoneModalPopup] = useState<boolean>(false);
  const [phonePopupDetailsBody, setPhonePopupDetailsBody] = useState<any>([
    {
      phoneId: '-',
      phoneNumber: '-',
      phoneType: '-',
      phoneTypeDesc: '-',
      extension: null,
      primary: '-',
      callTimeType: '-',
      callTimeTypeDesc: '-',
      active: '-',
      note: null,
    },
  ]);
  const [unHoldPopup, setUnHoldPopup] = useState<boolean>(false);

  // reference & residence
  const [referenceTableBody, setReferenceTableBody] = useState<
    PersonalReference[]
  >([
    {
      active: '-',
      relationshipTypeDesc: '-',
      firstName: '-',
      lastName: '-',
      doNotCall: '-',
      phoneNumber: '-',
      bestTimeToCallDesc: '-',
    },
  ]);
  const [residenceTableBody, setResidenceTableBody] = useState<
    LandlordReference[]
  >([
    {
      leaseCompanyName: '-',
      monthlyRent: '-',
      landlordFirstName: '-',
      landlordLastName: '-',
      phoneNumber: '-',
      bestTimeToCallDesc: '-',
      addressLine1: '-',
      addressLine2: '-',
      active: '-',
      leaseHolderName: '-',
      lengthOfLease: '-',
      lengthOfResidency: '-',
    },
  ]);

  const [employmentDetailsModal, setShowEmployementDetails] = useState(false);
  //emp
  const [employmentDetails, setEmployementDetails] = useState<
    EmployerReference[]
  >([]);
  const [residenceInfoModal, setShowResidencPopup] = useState(false);
  const [viewDetailsIndex, setViewDetailsIndex] = useState<number>(0);

  const [responseStatus, setResponseStatus] = useState<TabStatus>({
    activeAgreementTabStatus: 204,
    referenceTabStatus: 204,
    residenceTabStatus: 204,
    vehicleTabStatus: 204,
    employmentTabStatus: 204,
    taxExemptTabStatus: 204,
    inactiveAgreementTabStatus: 204,
    paymentHistoryTabStatus: 204,
    legalHoldTabStatus: 204,
    workHistoryTabStatus: 204,
  });

  const [showLegalDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [loaders, setLoaders] = useState({
    customerDetailsLoader: true,
    agreementsLoader: true,
    legalHoldLoader: true,
    paymentHistoryLoader: true,
    workHistoryLoader: true,
    taxExemptDDLoader: true,
  });

  const [legalHoldDetails, setLegalHoldDetails] = useState<any>({});
  const [category, setCaterogy] = useState<any>([]);

  const navigate = useHistory();
  const params: any = useParams();
  const endPoint = params.tabName;
  const customerId = params.customerId;
  const border = '25px !important';
  const [coCustomerName, setCoCustomerName] = useState<string>('-');
  const [coCustomerId, setCoCustomerId] = useState<number>(0);
  const [legalCheck, setLegalCheck] = useState<any>('');
  const legalCheckValues = legalCheck ? 'Unhold' : 'Legal Hold';

  useEffect(() => {
    setLoaders({
      customerDetailsLoader: true,
      agreementsLoader: true,
      legalHoldLoader: true,
      paymentHistoryLoader: true,
      workHistoryLoader: true,
      taxExemptDDLoader: true,
    });
    setCustomerDetails({
      ...customerDetails,
      name: '-',
      coCustomerName: '-',
      creationDate: '-',
    });
    fetchCustomerDetails();
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchCustomerDetails() {
    try {
      let customerDetailsResponse: any = '';
      let activeAgreementDetails: any = '';
      let inactiveAgreementDetails: any = '';
      let legalValidator: any = '';
      let legalCustomer: any = '';
      let menuConfig: any = '';
      if (customerId) {
        customerDetailsResponse = await getCustomerDetails(customerId);
        menuConfig = customerDetailsResponse.currentUserDetails;
        legalValidator = getLegalHoldCustomerStatus(customerDetailsResponse);
        setLegalCheck(legalValidator);
        legalCustomer = customerDetailsResponse.legalCustomer;
        activeAgreementDetails = customerDetailsResponse.activeAgreement;
        inactiveAgreementDetails = customerDetailsResponse.inActiveAgreement;
        customerDetailsResponse =
          customerDetailsResponse.customerDetailsResponse;
      } else {
        setErrorMessage('Invalid Customer');
        setErrorMessagePopup(true);
        navigate.push('/customer');
      }

      const details: any = customerDetailsResponse.data.GetCustomer.value;
      menuConfig = menuConfig?.menuConfigArray;
      if (typeof menuConfig == 'string') {
        menuConfig = JSON.parse(menuConfig);
      }
      if (menuConfig) {
        const allowedComponents = ['Tax', 'Legal', 'Finance'];
        const userPermissions: any = [];
        menuConfig.forEach((app: any) => {
          if (
            allowedComponents.includes(app.appComponentName) &&
            app.edit === 1
          ) {
            userPermissions.push(app.appComponentName);
          }
        });
        setUser(userPermissions);
      }

      if (customerDetailsResponse.status == 200) {
        const customerData: customerDetails = handleCustomerData(
          details,
          setPhonePopupDetailsBody,
          legalValidator,
          setCaterogy
        );

        setCustomerDetails(customerData);
        setPaymentOptionTableBody(details);

        const referenceDetails: PersonalReference[] =
          details?.personalReferences;
        const employeeDetails: EmployerReference[] =
          details?.employerReferences;
        const residence: LandlordReference[] = details?.landlordReferences;
        const vehicleInfo: Vehicle[] = details?.vehicles;
        const taxExamptData: any = details?.taxExempts;
        handleReferenceDetails(
          referenceDetails,
          setResponseStatus,
          setReferenceTableBody
        );

        handleEmploymentDetails(
          employeeDetails,
          setResponseStatus,
          setEmployementDetails
        );

        handleResidenceDetails(
          residence,
          setResponseStatus,
          setResidenceTableBody
        );

        handleVehicleDetails(
          vehicleInfo,
          setResponseStatus,
          setVehicleTableBody
        );

        handleTaxExemptDetails(
          taxExamptData,
          setResponseStatus,
          setTaxExamptTableBody
        );
        setLoaders((pre: any) => ({ ...pre, customerDetailsLoader: false }));

        getLegalData(
          legalCustomer,
          setResponseStatus,
          setLegalTableBody,
          setLoader
        );
        setLoaders((pre: any) => ({ ...pre, legalHoldLoader: false }));
      } else {
        setLoaders((pre: any) => ({ ...pre, customerDetailsLoader: false }));
        customerDetails.status = customerDetailsResponse.status;
      }

      inActiveAgreementData(
        inactiveAgreementDetails,
        setLoader,
        setInactiveAgreements,
        setResponseStatus
      );
      activeAgreementData(
        activeAgreementDetails,
        setLoader,
        setActiveAgreements,
        setResponseStatus,
        setCoCustomerId,
        setCoCustomerName
      );
      setLoaders((pre: any) => ({ ...pre, agreementsLoader: false }));

      const paymentAndWorkHistoryData = await getLegalCustomerData(customerId);
      const paymentHistory = paymentAndWorkHistoryData.paymentHistory;
      const workHistory = paymentAndWorkHistoryData.workHistory;
      const dropDowns = paymentAndWorkHistoryData.dropDowns;

      await getPaymentHistoryData(
        paymentHistory,
        setPaymentHistoryTableBody,
        setResponseStatus,
        setLoader
      );
      setLoaders((pre: any) => ({ ...pre, paymentHistoryLoader: false }));
      await getWorkHistoryDetails(
        workHistory,
        setWorkHistoryTableBody,
        setLoader,
        setResponseStatus,
        workHistoryTableBody
      );
      setLoaders((pre: any) => ({ ...pre, workHistoryLoader: false }));

      const taxExemptDropDown: dropdown[] = formateTaxExemptDropDown(dropDowns);
      const legalHoldDropDown: dropdown[] = formateLegalHoldDropDown(
        dropDowns,
        false
      );
      const legalUnHoldDropDown: dropdown[] = formateLegalHoldDropDown(
        dropDowns,
        true
      );
      setTaxExemptDenialDropDown(taxExemptDropDown);
      setlegalHoldStatusReason(legalHoldDropDown);
      setlegalUnHoldStatusReason(legalUnHoldDropDown);
      setLoaders((pre: any) => ({ ...pre, taxExemptDDLoader: false }));
    } catch (e: any) {
      // setErrorMessage('Something Went Wrong');
      setResponseStatus({
        activeAgreementTabStatus: 500,
        referenceTabStatus: 500,
        residenceTabStatus: 500,
        vehicleTabStatus: 500,
        employmentTabStatus: 500,
        taxExemptTabStatus: 500,
        inactiveAgreementTabStatus: 500,
        paymentHistoryTabStatus: 500,
        legalHoldTabStatus: 500,
        workHistoryTabStatus: 500,
      });
      customerDetails.status = 500;
      setCustomerDetails({ ...customerDetails, status: 500 });
      // setErrorMessagePopup(true);
      setLoaders({
        customerDetailsLoader: false,
        agreementsLoader: false,
        legalHoldLoader: false,
        paymentHistoryLoader: false,
        workHistoryLoader: false,
        taxExemptDDLoader: false,
      });
    }
  }

  const Agreementsobj = {
    tableBodyOne: activeAgreementsBody,
    tableBodyTwo: inActiveAgreementsBody,
    setLoader: setLoader,
    setErrorMessage: setErrorMessage,
    setErrorMessagePopup: setErrorMessagePopup,
    setActiveAgreements: setActiveAgreements,
    setInactiveAgreements: setInactiveAgreements,
    activeAgrStatus: responseStatus.activeAgreementTabStatus,
    inActiveAgrStatus: responseStatus.inactiveAgreementTabStatus,
    customerId: customerId,
    cardLoader: loaders.agreementsLoader,
  };

  const renderAgreementTab = () => <AgreementsTab value={Agreementsobj} />;

  //references tab content

  const referenceObject = {
    tableBodyOne: referenceTableBody,
    setReferenceTableBody: setReferenceTableBody,
    status: responseStatus.referenceTabStatus,
    cardLoader: loaders.customerDetailsLoader,
  };

  const renderReferanceTab = () => <ReferenceTab value={referenceObject} />;

  //vehicle info functions

  const vehicleInfoObject = {
    tableBodyOne: vehicleTableBody,
    setViewDetailsIndex: setViewDetailsIndex,
    setShowVehicleModal: setShowVehicleModal,
    setVehicleTableBody: setVehicleTableBody,
    status: responseStatus.vehicleTabStatus,
    cardLoader: loaders.customerDetailsLoader,
  };

  const renderVehicleInfoTab = () => (
    <VehicleInfoTab value={vehicleInfoObject} />
  );

  //employment tab

  const employmentObject = {
    tableBodyOne: employmentDetails,
    setShowEmployementDetails: setShowEmployementDetails,
    setEmployementDetails: setEmployementDetails,
    status: responseStatus.employmentTabStatus,
    viewDetailsIndex: viewDetailsIndex,
    cardLoader: loaders.customerDetailsLoader,
  };

  const renderEmploymentInfoTab = () => (
    <EmploymentTab value={employmentObject} />
  );

  //residence

  const residenceInfoObject = {
    tableBodyOne: residenceTableBody,
    setResidenceTableBody: setResidenceTableBody,
    setShowResidencPopup: setShowResidencPopup,
    status: responseStatus.residenceTabStatus,
    cardLoader: loaders.customerDetailsLoader,
  };

  const renderResidenceInfoTab = () => (
    <ResidenceTab value={residenceInfoObject} />
  );
  const taxExemptTabObject = {
    cardLoader: loaders.customerDetailsLoader,
    customerId: customerId,
    taxExemptDDLoader: loaders.taxExemptDDLoader,
    setLoaderCardMessage: setLoaderCardMessage,
    taxExemptTabStatus: customerDetails.status,
  };
  //tab content values
  const renderTaxExemptTab = () => <TaxExamptTab value={taxExemptTabObject} />;

  //legal table head part
  const legalTabHeading = () => (
    <>
      {legalTableHeading.map((heading: any, index: number) => {
        return (
          <RACTableCell
            key={index}
            className={`${classes.bold}  ${
              heading.heading === 'Action' ? classes.textCenter : ''
            }`}
          >
            {heading.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  //legal table body part
  const legalTableContent = () => (
    <>
      {legalTableBody.map((data: any, index: number) => {
        return (
          <RACTableRow style={{ backgroundColor: 'white' }} key={index}>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data.effectiveDate ? getFormatedDate(data.effectiveDate) : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data.holdReason ? getLegalHoldReasons(data.holdReason) : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data.closedDate ? yearToDateFormater(data.closedDate) : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data.closedReason ? getLegalHoldReasons(data.closedReason) : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textCenter}`}
            >
              <DetailsIcon
                className={classes.iconWidth}
                onClick={() => {
                  setShowDetailsModal(true);
                  setViewDetailsIndex(index);
                }}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  //legal tab content part
  const renderLegalTab = () => (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          {loaders.legalHoldLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid>
              {legalTableBody?.length != 0 &&
              responseStatus.legalHoldTabStatus == 200 ? (
                <RACTable
                  renderTableHead={legalTabHeading}
                  renderTableContent={legalTableContent}
                  stickyHeader
                  className={classes.fixTableHeadAgrTrsRec}
                />
              ) : responseStatus.legalHoldTabStatus == 204 ||
                legalTableBody?.length === 0 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <NoRecordsFound />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    No Records Found
                  </Typography>
                </Grid>
              ) : (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Something Went Wrong
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  const paymentHistoryObject = {
    tableBodyOne: paymentHistoryTableBody,
    setPaymentHistoryModel: setPaymentHistoryModel,
    setViewDetailsIndex: setViewDetailsIndex,
    setPaymentHistoryTableBody: setPaymentHistoryTableBody,
    setWorkHistoryTableBody: setWorkHistoryTableBody,
    status: responseStatus.paymentHistoryTabStatus,
    customerId: customerId,
    setHasMore: setPaymentHasMore,
    hasMore: paymentHasMore,
    fetchDataLoader: paymentFetchDataLoader,
    setFetchDataLoader: setPaymentFetchDataLoader,
    setOffset: setPaymentOffet,
    offset: paymentOffset,
    cardLoader: loaders.paymentHistoryLoader,
  };

  // payment history tab content part
  const renderPaymentHistoryTab = () => (
    <PaymentHistoryTab value={paymentHistoryObject} />
  );

  const paymentOptionObject = {
    tableBodyOne: paymentOptionTableBody,
    status: customerDetails.status,
    activeAgreementsBody: activeAgreementsBody,
    inActiveAgreementsBody: inActiveAgreementsBody,
    cardLoader: loaders.customerDetailsLoader,
  };

  //payment option tab content part
  const renderPaymentOptionTab = () => (
    <PaymentOptionTab value={paymentOptionObject} />
  );

  const workHistoryObject = {
    tableBodyOne: fullWorkHistoryTableBody,
    tableBodyTwo: paymentHistoryTableBody,
    setPaymentHistoryTableBody: setPaymentHistoryTableBody,
    setWorkHistoryModel: setWorkHistoryModel,
    setViewDetailsIndex: setViewDetailsIndex,
    setWorkHistoryTableBody: setWorkHistoryTableBody,
    status: responseStatus.workHistoryTabStatus,
    paymentStatus: responseStatus.paymentHistoryTabStatus,
    customerId: customerId,
    setLoader: setLoader,
    setResponseStatus: setResponseStatus,
    setHasMore: setWorkHistoryHasMore,
    hasMore: workHistoryHasMore,
    fetchDataLoader: workHistoryFetchDataLoader,
    setFetchDataLoader: setWorkHistoryFetchDataLoader,
    setOffset: setWorkHistoryOffet,
    offset: workHistoryOffset,
    paymentHistoryOffset: paymentOffset,
    setPaymentHistoryOffset: setPaymentOffet,
    paymentHasMore: paymentHasMore,
    setPaymentHasMore: setPaymentHasMore,
    paymentFetchDataLoader: paymentFetchDataLoader,
    setPaymentFetchDataLoader: setPaymentFetchDataLoader,
    cardLoader: loaders.workHistoryLoader,
  };

  // payment option tab content part
  const renderWorkHistoryTab = () => (
    <WorkHistoryTab value={workHistoryObject} />
  );

  const phoneDetailsHeading = () => (
    <>
      {phonePopupDetailsHeading.map((obj: any, index: any) => {
        return (
          <RACTableCell key={index}>
            {obj.heading ? obj.heading : ''}
          </RACTableCell>
        );
      })}
    </>
  );

  const phoneDetailsBody = () =>
    phonePopupDetailsBody.map((obj: any, index: any) => {
      return (
        <RACTableRow style={{ backgroundColor: 'white' }} key={index}>
          <RACTableCell>
            {obj.active == 'Y' ? 'Active' : 'In Active'}
          </RACTableCell>
          <RACTableCell>
            {obj.phoneNumber
              ? `(${obj.phoneNumber.slice(0, 3)}) ${obj.phoneNumber.slice(
                  3,
                  6
                )}-${obj.phoneNumber.slice(6)}`
              : '-'}
          </RACTableCell>
          <RACTableCell>{obj.extension ? obj.extension : '-'}</RACTableCell>
          <RACTableCell>
            {obj.callTimeTypeDesc ? obj.callTimeTypeDesc : '-'}
          </RACTableCell>
          <RACTableCell>
            {obj.phoneTypeDesc ? obj.phoneTypeDesc : '-'}
          </RACTableCell>
          <RACTableCell>{obj.primary == 'Y' ? 'Yes' : 'No'}</RACTableCell>
        </RACTableRow>
      );
    });

  const renderPhoneDetails = () => (
    <Grid container md={12} style={{ width: '100%' }}>
      <Grid item md={12}>
        {phonePopupDetailsBody.length > 0 ? (
          <RACTable
            renderTableHead={phoneDetailsHeading}
            renderTableContent={phoneDetailsBody}
          />
        ) : (
          <Grid className={`${classes.textCenter} ${classes.mt5}`}>
            <NoRecordsFound />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              No Records Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
  const renderUnholdPopup = () => (
    <Grid container md={12}>
      <Grid item md={6} className={classes.mL15}>
        <Typography variant="h6" className={`${classes.title}`}>
          Reason
        </Typography>
        <RACSelect
          loading={loaders.taxExemptDDLoader}
          options={legalCheck ? legalHoldStatusReason : legalUnHoldStatusReason}
          defaultValue={
            legalHoldDetails?.holdRefCode
              ? legalHoldDetails?.holdRefCode
              : legalHoldDetails?.unholdRefCode
              ? legalHoldDetails?.unholdRefCode
              : '0'
          }
          onChange={(e: any) => {
            handleOnChange(
              e.target.value,
              legalCheck ? 'unholdRefCode' : 'holdRefCode',
              setLegalHoldDetails,
              legalHoldDetails
            );
          }}
        />
      </Grid>
      <Grid item md={6}></Grid>
      <Grid item md={8}></Grid>
      <Grid item md={4} style={{ float: 'right' }}>
        <RACButton
          style={{ padding: '7px 30px', marginTop: '40px', float: 'right' }}
          variant="contained"
          color="primary"
          disabled={Object.keys(legalHoldDetails).length == 0}
          onClick={() => {
            setUnHoldPopup(false);
            manageLegalHold(
              customerId,
              legalHoldDetails,
              legalTableBody,
              legalCheck,
              setLoader,
              setLegalTableBody,
              setResponseStatus,
              setLegalCheck,
              setUnHoldPopup,
              setLegalHoldDetails,
              setCaterogy,
              category,
              setErrorMessage,
              setErrorMessagePopup,
              setLoaderCardMessage
            );
          }}
        >
          Save
        </RACButton>
      </Grid>
    </Grid>
  );

  function showErrorMessagePopup() {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>

          <Typography className={`${classes.formLabel} ${classes.mt3}`}>
            {errorMessage}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.mt3} ${classes.textCenter}`}>
          <RACButton
            style={{ padding: '7px 10px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMessagePopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  }

  function getToolTipIcon(customerDetails: any) {
    return customerDetails?.contact?.phone != '-' ? (
      <ToolTipIcon
        className={classes.phoneTooltipIcon}
        onClick={() => {
          setPhoneModalPopup(true);
        }}
      />
    ) : (
      <></>
    );
  }

  function getCocustomerName(coCustomerName: any) {
    return coCustomerName
      ? coCustomerName == customerDetails.name
        ? '-'
        : coCustomerName
      : '-';
  }

  return (
    <div className={`${classes.lightGrayBgColor}`} style={{ height: '60vh' }}>
      <>
        <Grid container>
          <Grid container className={`${classes.p01}`}>
            <Grid container className={`${classes.width100}`}>
              <Grid item className={`${classes.width70}`}>
                <Typography
                  variant="h1"
                  className={`${classes.floatLeft} ${classes.mt43} ${classes.title} `}
                >
                  Customer Details
                </Typography>
              </Grid>
              <Grid className={`${classes.width30}`}>
                <Grid container>
                  <Grid item className={`${classes.width125}`}>
                    <Typography variant="body1" className={` ${classes.mt3}`}>
                      Customer
                    </Typography>
                    <Typography
                      className={`${classes.blueCryolaColor} ${classes.mt1} ${classes.pointer}`}
                    >
                      {customerDetails.name ? customerDetails.name : '-'}
                    </Typography>
                  </Grid>
                  <Grid item className={`${classes.width125}`}>
                    <Typography className={` ${classes.mt3}`}>
                      Co-Customer
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`${classes.blueCryolaColor} ${classes.mt1} ${classes.pointer}`}
                      onClick={() => {
                        customerDetails.coCustomerId
                          ? navigate.push(
                              `/operations/customer/details/${coCustomerId}`
                            )
                          : '';
                      }}
                    >
                      {getCocustomerName(coCustomerName)}
                    </Typography>
                  </Grid>
                  <Grid item className={`${classes.width125}`}>
                    <Typography variant="body1" className={` ${classes.mt3}`}>
                      Creation Date
                    </Typography>
                    <Typography className={`${classes.mt1}`}>
                      {customerDetails?.creationDate
                        ? customerDetails?.creationDate
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Card
                className={`${classes.card} ${classes.mt3} ${classes.cardHeight}`}
              >
                <CardContent>
                  {customerDetails?.status == 200 &&
                  !loaders.customerDetailsLoader ? (
                    <Grid container md={12}>
                      {/* contact */}
                      <Grid item md={5}>
                        <Typography
                          variant="h4"
                          className={`${classes.floatLeft} `}
                        >
                          Contact
                        </Typography>
                        <Grid container md={12}>
                          <Grid item md={4}>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Primary Address
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Mailing Address
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Phone #
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Email Address
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Category
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.contact?.primaryAddress}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.contact?.mailingAddress}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.contact?.phone}
                              {getToolTipIcon(customerDetails)}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.contact?.emailAddress}
                            </Typography>
                            {category.length != 0 ? (
                              <Grid item className={classes.p10px0px}>
                                <Typography
                                  className={`${classes.categoryTag}`}
                                >
                                  {category}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid item className={classes.p10px0px}>
                                <Typography>-</Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* personal */}
                      <Grid item md={4}>
                        <Typography
                          variant="h4"
                          className={`${classes.floatLeft} `}
                        >
                          Personal
                        </Typography>
                        <Grid container md={12}>
                          <Grid item md={5}>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Date of Birth
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Social Society #
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Gov. ID Type
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Gov. ID #
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Issued State
                            </Typography>
                          </Grid>
                          <Grid item md={7}>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.personal?.dateOfBirth}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.personal?.socialSecurity}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.personal?.govIdType}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.personal?.govId}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.personal?.issuedState}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* preferences */}
                      <Grid item md={3}>
                        <Typography
                          variant="h4"
                          className={`${classes.floatLeft} `}
                        >
                          Preferences
                        </Typography>
                        <Grid container md={12}>
                          <Grid item md={7}>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Preferred Language
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Payment Remainder Calls
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Preferred Method of Contact
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Phone Solicitation
                            </Typography>
                            <Typography
                              className={`${classes.semiBold}  ${classes.p10px0px}`}
                            >
                              Mail Solicitation
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.preferences?.preferredLanguage}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {
                                customerDetails?.preferences
                                  ?.paymentRemainderCalls
                              }
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {
                                customerDetails?.preferences
                                  ?.preferredMethodOfContact
                              }
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.preferences?.phoneSolicitation}
                            </Typography>
                            <Typography
                              className={`${classes.regular}  ${classes.p10px0px}`}
                            >
                              {customerDetails?.preferences?.mailSolicitation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : loaders.customerDetailsLoader ? (
                    <Grid className={classes.customerCardLoader}>
                      <Loader />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      md={12}
                      className={`${classes.textCenter} ${classes.mt1}`}
                    >
                      <Grid
                        item
                        md={12}
                        className={`${classes.textCenter} ${classes.mt1} ${classes.cardAlignment}`}
                      >
                        <AlertIcon />
                        <Typography
                          className={`${classes.textCenter}  ${classes.mt3}`}
                        >
                          Something Went Wrong
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            md={12}
            id="unique"
            className={`${classes.tabsWidth} ${classes.mt4} ${classes.tabstest}`}
            style={{ backgroundColor: 'white' }}
          >
            <RACTabs
              tabs={[
                'Agreements',
                'References',
                'Employment',
                'Residence',
                'Vehicle Info',
                'Tax Exempt',
                'Legal',
                'Payment Option',
                'Payment History',
                'Work History',
              ]}
              contentForTabs={[
                renderAgreementTab(),
                renderReferanceTab(),
                renderEmploymentInfoTab(),
                renderResidenceInfoTab(),
                renderVehicleInfoTab(),
                renderTaxExemptTab(),
                renderLegalTab(),
                renderPaymentOptionTab(),
                renderPaymentHistoryTab(),
                renderWorkHistoryTab(),
              ]}
              defaultTab={
                endPoint == 'taxexempt' ? 5 : endPoint == 'legal' ? 6 : 0
              }
              classes={{
                tabs: classes.textBlack,
              }}
            ></RACTabs>
          </Grid>

          <Grid>
            <RACModalCard
              isOpen={employmentDetailsModal}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal2}
              borderRadius={border}
              title={'Employment Details'}
              onClose={() => {
                setShowEmployementDetails(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {employmentModal(classes, employmentDetails, viewDetailsIndex)}
            </RACModalCard>
            <RACModalCard
              isOpen={showLegalDetailsModal}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal4}
              borderRadius={border}
              title={'Legal Hold Details'}
              onClose={() => {
                setShowDetailsModal(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {showLegalDetailsPopup(classes, legalTableBody, viewDetailsIndex)}
            </RACModalCard>
            <RACModalCard
              isOpen={paymentHistoryModel}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal2}
              borderRadius={border}
              title={'Payment Details'}
              onClose={() => {
                setPaymentHistoryModel(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {showPaymentHistoryPopup(
                classes,
                paymentHistoryTableBody,
                viewDetailsIndex
              )}
            </RACModalCard>
            <RACModalCard
              isOpen={workHistoryModel}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal2}
              borderRadius={border}
              title={'Work History Details'}
              onClose={() => {
                setWorkHistoryModel(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {showWorkHistoryPopup(
                classes,
                fullWorkHistoryTableBody,
                viewDetailsIndex
              )}
            </RACModalCard>
            <RACModalCard
              isOpen={residenceInfoModal}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal2}
              borderRadius={border}
              title={'Residence Details'}
              onClose={() => {
                setShowResidencPopup(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {showResidenceDetailsPopup(
                residenceTableBody,
                viewDetailsIndex,
                customerDetails,
                classes
              )}
            </RACModalCard>
            <RACModalCard
              isOpen={showVehicleModal}
              closeIcon={true}
              maxWidth={'md'}
              className={classes.customModal3}
              borderRadius={border}
              title={'Vehicle Information'}
              onClose={() => {
                setShowVehicleModal(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {vehicleModal(classes, vehicleTableBody, viewDetailsIndex)}
            </RACModalCard>
            <RACModalCard
              isOpen={unHoldPopup}
              closeIcon={true}
              maxWidth={'xs'}
              borderRadius={border}
              title={legalCheck ? 'Unhold Customer' : 'Legal Hold Customer'}
              onClose={() => {
                setUnHoldPopup(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {renderUnholdPopup()}
            </RACModalCard>
            <RACModalCard
              isOpen={phoneModal}
              closeIcon={true}
              maxWidth={'sm'}
              borderRadius={border}
              title={'Phone Numbers'}
              onClose={() => {
                setPhoneModalPopup(false);
              }}
              TitleClassName={classes.textCenter}
            >
              {renderPhoneDetails()}
            </RACModalCard>
            <RACModalCard
              isOpen={errorMessagePopup}
              closeIcon={false}
              maxWidth={'xs'}
              borderRadius={border}
            >
              {showErrorMessagePopup()}
            </RACModalCard>
            <RACModalCard
              isOpen={loader}
              closeIcon={false}
              maxWidth={'xs'}
              borderRadius={border}
              TitleClassName={`${classes.textCenter}`}
            >
              {
                <Grid className={` ${classes.textCenter}`}>
                  <CircularProgress />
                  <Typography className={`${classes.formLabel} ${classes.mt3}`}>
                    {loaderCardMessage}
                  </Typography>
                  <Typography
                    className={`${classes.formLabel} ${classes.mt3} ${classes.colorGray}`}
                  >
                    Please wait...
                  </Typography>
                </Grid>
              }
            </RACModalCard>
            <RACModalCard
              className={classes.loaderBackground}
              isOpen={pageLoader}
              closeIcon={false}
              maxWidth={'xs'}
              borderRadius={border}
              TitleClassName={`${classes.textCenter}`}
            >
              {
                <Grid className={` ${classes.textCenter}`}>
                  <CircularProgress />
                </Grid>
              }
            </RACModalCard>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container md={12} className={`${classes.fixedBottom}`}>
            <Grid item md={6}>
              <RACButton
                className={`${classes.floatLeft}`}
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate.goBack();
                }}
              >
                Cancel
              </RACButton>
            </Grid>
            <Grid item md={6}>
              {user.includes('Legal') ? (
                <RACButton
                  className={`${classes.floatRight}`}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setLegalHoldDetails({});
                    setUnHoldPopup(true);
                  }}
                >
                  {legalCheckValues}
                </RACButton>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
