/* eslint-disable */
import {
  Grid,
  RACButton,
  RACDatePicker,
  RACModalCard,
  RACTextbox,
  RACTooltip,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { AppConfigurationStyles } from '../../../JsStyles/AppConfigurationStyles';
import moment from 'moment';
import { getOrgRulesSummary, manageOrganizationRule } from '../../../api/user';
import { PinPopup } from '../MicroComponents/PinPopup';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';
import {
  convertStringToObject,
  getBusinessValueIcon,
  isMatchingObject,
  validDatatype,
} from '../../../utils/AppconfigurationUtils';
import DynamicPopup from './DynamicPopup';
import { NewRuleRequest } from '../../../Interfaces/Inventory/AppConfigInterface';
import { booleanValues } from '../../../constants/constants';
import { ReactComponent as AddSACDays } from "../../../Icons/ItemAddition.svg";
import { ReactComponent as DeleteSACDays } from "../../../Icons/Record delete.svg";

export function EditRule(props?: any) {
  const classes = AppConfigurationStyles();

  const [paramValueError, setParamValueError] = useState<string>('');
  const {
    viewMode,
    request,
    setOrganizationRules,
    setResultWidget,
    ruleConstraints,
    state,
    company,
    franchiseeUser
  } = useContext(AppConfigurationContext);
  const { ruleInfo, closePopupFunction } = props;
  const [popupType, setPopupType] = useState<string>('ERROR');
  const [ruleDetails, setRuleDetails] = useState<any>({});
  const [showPinPopup, setShowPinPopup] = useState<boolean>(false);
  const [showErrorCard,setShowErrorPopup]=useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([{ term: '', sac: '' }]);
  const [sacRule, setSacRule] = useState<boolean>(false);

  const getHierarchy=(type:string,hierarchyName:string)=>{
    if(type=='STATE'){
      
      const chosenState=state?.find((stateInfo:any)=>stateInfo.referenceName==hierarchyName);
      return chosenState?.referenceValue;
    }
    else if(type=='COMPANY'){
      const chosenCompany=company?.find((companyInfo:any)=>companyInfo.referenceName==hierarchyName);
     return chosenCompany?.referenceValue;
    }
  };

  const formPayload = () => {
    const payload:NewRuleRequest = {
      paramCategoryName: ruleDetails.paramCategoryName,
      paramGroupName: ruleDetails.paramGroupName,
      paramKeyName: ruleDetails.paramKeyName,
      hierarchyType: '',
      hierarchyValue: '',
      parentHierarchyType: null,
      parentHierarchyValue: null,
      startDate: ruleDetails.startDate,
      endDate: ruleDetails.endDate,
      paramName: ruleDetails.paramName?ruleDetails.paramName:null,
      paramValue: (ruleDetails.paramValue).toString(),
      action: 'UPDATE',
    };
  
    if(ruleDetails.hierarchyType=='STATE'){      
      payload.hierarchyType='STATE'
      payload.hierarchyValue=getHierarchy('STATE',ruleDetails.hierarchyValue);
    }
    else if(ruleDetails.hierarchyType=='COMPANY'){
      payload.hierarchyValue=getHierarchy('COMPANY',ruleDetails.hierarchyValue)
      payload.hierarchyType='COMPANY'
    }
   
    else if (ruleDetails.hierarchyType.includes('+')) {
      const hierarchy = ruleDetails.hierarchyType.split('+');
      const hierarchyValue = ruleDetails.hierarchyValue.split('+');
      
      payload.hierarchyType =hierarchy[1] ;
      payload.parentHierarchyType =  hierarchy[0];
      if(hierarchy[1]=='STATE'){
        payload.hierarchyValue = getHierarchy('STATE',hierarchyValue[1])
      }
      else {
        payload.hierarchyValue = hierarchyValue[1];
      }

      if(hierarchy[0]=='COMPANY'){
        payload.parentHierarchyValue = getHierarchy('COMPANY',hierarchyValue[0]);
      }
      else{
        payload.parentHierarchyValue = hierarchyValue[0];
      }
            
    }
    else {
      payload.hierarchyType=ruleDetails.hierarchyType;
      payload.hierarchyValue=ruleDetails.hierarchyValue
    } 
    return payload;
  };

  const reloadPage = async () => {
    closePopupFunction(false);
    setResultWidget(1);
    const payload ={...request};
    payload.offset=0;
    payload.limit=10;
    const summaryResponse: any = await getOrgRulesSummary(payload);
    if (
      summaryResponse?.status == 200 &&
      summaryResponse?.data?.organizationRule
    ) {
      setOrganizationRules(summaryResponse.data.organizationRule);
      setResultWidget(200);
    } else if (
      summaryResponse?.status == 204 ||
      summaryResponse?.data?.organizationRule?.length == 0
    ) {
      setResultWidget(204);
    } else {
      setResultWidget(500);
    }
    closePopupFunction(false);
  };

  const updateOrganiationRule = async () => {
    setLoader(true);

    const updatePayload = await formPayload();

    const updateResponse = await manageOrganizationRule(updatePayload);
    
    if (
      updateResponse &&
      updateResponse.status == 200 &&
      updateResponse.data?.paramConfigListOfValueId
    ) {
      setShowSaved(true);
    }
    else if(updateResponse &&
      updateResponse.status ==400){
        setPopupType('BADREQUEST');
      setShowErrorPopup(true);
    }
    else{
      setPopupType('ERROR');
      setShowErrorPopup(true);
    }

    setLoader(false);
  };

  const validateBusinessValue = () => {
    try {;
      const time=/^(1[0-2]|0?[1-9]):([0-5][0-9])\s(AM|PM)$/;
        const ruleInfo=ruleConstraints[ruleDetails.paramKeyId];
        let isValidValue=false;
        if(ruleInfo.datatype=='OBJECT'||ruleInfo.datatype=='JSON'){
          isValidValue = isMatchingObject(
            convertStringToObject(ruleInfo.template),
            convertStringToObject(ruleDetails.paramValue)
          )          
        }
        else if(ruleInfo.datatype=='BOOLEAN'){
          isValidValue=booleanValues.includes(ruleDetails.paramValue)
        }
        else{
          isValidValue = true
        }

        if(!isValidValue){
          setParamValueError('Please provide an appropriate business value')
        }
        else if(ruleInfo.datatype=='TIME'&&!time.test(ruleDetails.paramValue)){
          setParamValueError('Please provide an appropriate business value')
        }
        else{
          setParamValueError('')
          setShowPinPopup(true);
        }
        
      }
     catch (error: any) {
      setPopupType('ERROR');
      setShowErrorPopup(true);
    }
  };

  useEffect(() => {
    setRuleDetails({
      categoryMasterDisplayName: ruleInfo?.categoryMasterDisplayName
        ? ruleInfo?.categoryMasterDisplayName
        : '',
      paramCategoryMasterName: ruleInfo?.paramCategoryMasterName
        ? ruleInfo?.paramCategoryMasterName
        : '',
      paramCategoryName: ruleInfo?.paramCategoryName
        ? ruleInfo?.paramCategoryName
        : '',
      paramGroupName: ruleInfo?.paramGroupName ? ruleInfo?.paramGroupName : '',
      categoryDisplayName: ruleInfo?.categoryDisplayName
        ? ruleInfo?.categoryDisplayName
        : '',
      groupDisplayName: ruleInfo?.groupDisplayName
        ? ruleInfo?.groupDisplayName
        : '',
      paramKeyName: ruleInfo?.paramKeyName ? ruleInfo?.paramKeyName : '',
      keyDisplayName: ruleInfo?.keyDisplayName ? ruleInfo?.keyDisplayName : '',
      paramValueDatatype: ruleInfo?.paramValueDatatype
        ? ruleInfo?.paramValueDatatype
        : '',
      paramKeyDesc: ruleInfo?.paramKeyDesc ? ruleInfo?.paramKeyDesc : '',
      hierarchyType: ruleInfo?.hierarchyType ? ruleInfo?.hierarchyType : '',
      hierarchyValue: ruleInfo?.hierarchyValue ? ruleInfo?.hierarchyValue : '',
      startDate: ruleInfo?.startDate,
      endDate: ruleInfo?.endDate,
      paramValue: ruleInfo?.paramValue&&ruleInfo.paramValueMultiplier!='OTHER' ?Number(ruleInfo.paramValueMultiplier)*Number(ruleInfo.paramValue): ruleInfo?.paramValue ?ruleInfo?.paramValue : '',
      paramName: ruleInfo?.paramName ? ruleInfo?.paramName : '',
      paramKeyId:ruleInfo?.paramKeyId?ruleInfo?.paramKeyId:null
    });

    if(ruleInfo?.paramKeyName == 'SameAsCashDays' || ruleInfo?.paramKeyName == 'SameAsCashDaysPrinted'){
      const sacRules = JSON.parse(ruleInfo?.paramValue)
      setRows(sacRules.sameascash)
      setSacRule(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBusinessValue=(e:any)=>{
    let options={...ruleDetails};
    if(e.target.value==''){
      options.paramValue='';
    }
    else {
      const validData=validDatatype(ruleConstraints[ruleDetails.paramKeyId].datatype,e.target.value);
      if(validData){
        options.paramValue=e.target.value
      }
    }
    setRuleDetails(options);
  }

  const enableSave=()=>{
    const existingValue=ruleInfo.paramValueMultiplier!='OTHER'?Number(ruleInfo.paramValueMultiplier)*Number(ruleInfo.paramValue): ruleInfo?.paramValue 
    if((ruleDetails.paramValue == existingValue && ruleDetails.endDate == ruleInfo.endDate)||( ruleDetails.paramValue === '' || ruleDetails.endDate === '')||loader){
      return false
    } else{
      return true
    }
  }
  const ruleInfoCard = () => (
    <>
      <Grid
        container
        md={12}
        style={{
          backgroundColor: '#eff4ff',
          margin: '2px 0px',
          borderRadius: '10px',
          minHeight: '4rem',
          padding: '1rem',
        }}
      >
        <Grid container md={12} style={{ marginBottom: '2rem' }}>
          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>Category :</Typography>
            </Grid>
            <Grid item md={8}>
              <RACTooltip
                placement={'top-start'}
                style={{
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  backgroundColor: 'white !important',
                  zIndex: 10,
                }}
                className={classes.toooltipStyle}
                title={
                  <>
                    <Grid>
                      <Typography>{ruleDetails.paramCategoryName}</Typography>
                    </Grid>
                  </>
                }
              >
                <Typography style={{ display: 'inline', color: '#505779' }}>
                  {!ruleDetails.categoryDisplayName
                    ? ruleDetails.paramCategoryName
                    : ruleDetails.categoryDisplayName}
                </Typography>
              </RACTooltip>
            </Grid>
          </Grid>

          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>Group :</Typography>
            </Grid>
            <Grid item md={8}>
              <RACTooltip
                placement={'top-start'}
                style={{
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  backgroundColor: 'white !important',
                  zIndex: 10,
                }}
                className={classes.toooltipStyle}
                title={
                  <>
                    <Grid>
                      <Typography>{ruleDetails.paramGroupName}</Typography>
                    </Grid>
                  </>
                }
              >
                <Typography style={{ display: 'inline', color: '#505779' }}>
                  {!ruleDetails.groupDisplayName
                    ? ruleDetails.paramGroupName
                    : ruleDetails.groupDisplayName}
                </Typography>
              </RACTooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} style={{ marginBottom: '2rem' }}>
          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>Rule Name :</Typography>
            </Grid>
            <Grid item md={8}>
              <RACTooltip
                placement={'top-start'}
                style={{
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  backgroundColor: 'white !important',
                  zIndex: 10,
                }}
                className={classes.toooltipStyle}
                title={
                  <>
                    <Grid>
                      <Typography>{ruleDetails.paramKeyName}</Typography>
                    </Grid>
                  </>
                }
              >
                <Typography style={{ display: 'inline', color: '#505779' }}>
                  {ruleDetails.paramName ? ruleDetails.paramName + ' - ' : ''}
                  {!ruleDetails.keyDisplayName
                    ? ruleDetails.paramKeyName
                    : ruleDetails.keyDisplayName}
                </Typography>
              </RACTooltip>
            </Grid>
          </Grid>
          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>
                Description :
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography style={{ display: 'inline', color: '#505779' }}>
                {ruleDetails.paramKeyDesc ? ruleDetails.paramKeyDesc : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} style={{ marginBottom: '2rem' }}>
          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>
                Hierarchy Type :
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography style={{ display: 'inline', color: '#505779' }}>
                {ruleDetails.hierarchyType ? ruleDetails.hierarchyType : '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container md={6}>
            <Grid item md={4}>
              <Typography style={{ display: 'inline' }}>
                Hierarchy Value :
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography style={{ display: 'inline', color: '#505779' }}>
                {ruleDetails.hierarchyValue ? ruleDetails.hierarchyValue : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const handleSACParamValue=(e:any)=>{
    debugger
    const options={...ruleDetails};

    const value = validDatatype(ruleConstraints[ruleDetails.paramKeyId].datatype,e);
      
    if(value){
      const sacValue = {
        sameascash: value
      }
      options.paramValue = JSON.stringify(sacValue);
    }

    setRuleDetails(options);
  }

  const isValidSac = () => {
    return rows.every(row => row.term !== '' && row.sac !== '');
  };

  const ruleData = () => {
    if(ruleDetails.paramKeyName == 'SameAsCashDays' || ruleDetails.paramKeyName == 'SameAsCashDaysPrinted'){

      const handleTermChange = (index: number, value: number) => {
        const newRows = [...rows];
        newRows[index].term = value;
        setRows(newRows);
        handleSACParamValue(rows)
      };
    
      const handleSACChange = (index: number, value: number) => {
        const newRows = [...rows];
        newRows[index].sac = value;
        setRows(newRows);
        handleSACParamValue(rows)
      };

      const handleAddClick = () => {
        setRows([...rows, { term: '', sac: '' }]);
      };
    
      const handleRemoveClick = (index: number) => {
        if (rows.length > 1) {
          const newRows = rows.filter((_, i) => i !== index)
          console.log('newRows', newRows)
          setRows(newRows);
          handleSACParamValue(newRows)
        }
      };

      const renderRows = () => {
        return rows.map((row, index) => (
          <React.Fragment key={index}>
            <Grid item md={6} style={{ marginBottom: '2px', display: 'flex' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomLeftRadius: "25%",
                  borderTopLeftRadius: "25%",
                  width: "35px",
                  backgroundColor: "rgb(220,223,228)",
                  border: "1px solid lightgrey",
                }}
              >
                {getBusinessValueIcon('TERM')}
              </div>
              <RACTextbox
                value={row.term}
                className={classes.viewValue}
                type='number'
                disabled={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? true : false}
                OnChange={(e) => handleTermChange(index, e.target.value)}
                placeHolder={'Enter the Term'}
              />
            </Grid>
    
            <Grid item md={6} style={{ marginBottom: '2px', display: 'flex', paddingLeft: '1rem' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomLeftRadius: "25%",
                  borderTopLeftRadius: "25%",
                  width: "35px",
                  backgroundColor: "rgb(220,223,228)",
                  border: "1px solid lightgrey",
                }}
              >
                {getBusinessValueIcon('DAYS')}
              </div>
              <RACTextbox
                value={row.sac}
                className={classes.viewValueSac}
                type='number'
                disabled={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? true : false}
                OnChange={(e) => handleSACChange(index, e.target.value)}
                placeHolder={'Enter the SAC Days'}
              />
              {index > 0 && (
                <Grid style={{ display: 'flex', paddingLeft: '95px' }}>
                  <Typography style={{ display: "flex", paddingLeft: '5px', marginTop: '10px' }}>
                    <DeleteSACDays  style={ !(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? {cursor:'pointer'} : {}} onClick={() => {
                      (viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? {} : handleRemoveClick(index)}} 
                    />
                  </Typography>
                </Grid>
              )}
              {index == 0 && (
                <Grid style={{ display: 'flex', paddingLeft: '90px' }}>
                    <AddSACDays style={ isValidSac() && !(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? {cursor:'pointer'} : {}}
                      onClick={() => { 
                        isValidSac() && !(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? handleAddClick(): {}
                        }
                      }
                    />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ));
      };

      return (
        <>

          <Grid container md={12} style={{ marginTop: '1rem', marginBottom: '5px' }}>
            <Grid item md={6}>
              <Typography>Term <span style={{ color: 'red' }}>*</span></Typography>
            </Grid>
            <Grid item md={6} style={{paddingLeft: '1rem', }}>
              <Typography>SAC <span style={{ color: 'red' }}>*</span></Typography>
            </Grid>
          </Grid>
    
          <Grid container spacing={2}>
            {renderRows()}
          </Grid>

          <Grid container md={12}>
            <Grid item md={6}>
              <Typography style={{color:'red'}}>{paramValueError}</Typography>
            </Grid>
          </Grid>
  
          <Grid container md={12} style={{ marginTop: '1rem' }}>

            <Grid md={6} item style={{ paddingRight: '1rem' }}>
              <RACDatePicker
                label={'Start Date'}
                value={moment(ruleDetails.startDate).format('YYYY-MM-DD')}
                disabled={true}
              />
            </Grid>

            <Grid md={6} item style={{ paddingLeft: '1rem' }}>
              <RACDatePicker
                label={'End Date'}
                value={ruleDetails.endDate}
                disabled={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? true : false}
                inputProps={{
                  min: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }}
                onChange={(e: any) => {
                  setRuleDetails({
                    ...ruleDetails,
                    endDate: e,
                  });
                }}
              />
            </Grid>
          </Grid>
        </>
      );
    }
    else{
      return (
        <>
        <Grid container md={12}>
          <Grid container md={12} style={{ marginTop: '1rem' }}>
            <Grid container md={6} style={{ paddingRight: '1rem' }}>
              <Typography>Business Value</Typography>
              <Grid item md={12} style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottomLeftRadius: '25%',
                    borderTopLeftRadius: '25%',
                    width: '35px',
                    backgroundColor: 'rgb(220,223,228)',
                    border: '1px solid lightgrey',
                  }}
                >
                  {getBusinessValueIcon(ruleInfo?.paramValueDatatype)}
                </div>
                <RACTextbox
                  className={classes.viewValue}
                  value={ruleDetails.paramValue}
                  disabled={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? true : false}
                  OnChange={handleBusinessValue}
                />
              </Grid>
            </Grid>
          </Grid>
  
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography style={{color:'red'}}>{paramValueError}</Typography>
            </Grid>
          </Grid>
  
          <Grid container md={12} style={{ marginTop: '1rem' }}>
            <Grid md={6} item style={{ paddingRight: '1rem' }}>
              <RACDatePicker
                label={'Start Date'}
                value={moment(ruleDetails.startDate).format('YYYY-MM-DD')}
                disabled={true}
              />
            </Grid>
            <Grid md={6} item style={{ paddingLeft: '1rem' }}>
              <RACDatePicker
                label={'End Date'}
                value={ruleDetails.endDate}
                disabled={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true )) ? true : false}
                inputProps={{
                  min: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }}
                onChange={(e: any) => {
                  setRuleDetails({
                    ...ruleDetails,
                    endDate: e,
                  });
                }}
              />
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    }
  }

  const actionButtons = () => (
    <>
      <Grid container md={12} style={{ marginTop: '1rem' }}>
        <Grid item md={12}>
          {(franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true ) ? (
            <></>
          ) :
          (!viewMode) ? (
            <RACButton
              variant="contained"
              color="primary"
              style={{ float: 'right' }}
             
              disabled={ (sacRule && !isValidSac()) || !enableSave() }
              loading={loader}
              onClick={() => {
                
                validateBusinessValue();
              }}
            >
              Save
            </RACButton>
          ) : (
            <></>
          )}
          <RACButton
            variant="outlined"
            color="primary"
            style={{
              float: 'right',
              padding: '11px 25px',
              marginRight: '16px',
            }}
            onClick={() => {
              closePopupFunction(false);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {showPinPopup ? (
        <PinPopup
          closePopupFunction={setShowPinPopup}
          onCompleteFunction={updateOrganiationRule}
        />
      ) :showSaved ? (
        <DynamicPopup
          type={'SAVED'}
          closePopupFunction={setShowSaved}
          onCloseFunction={reloadPage}
        />
      ) : showErrorCard?(
        <DynamicPopup
        type={popupType}
        closePopupFunction={setShowErrorPopup}
        />
      ):(
        <RACModalCard
          isOpen={true}
          closeIcon={true}
          onClose={() => {
            closePopupFunction(false);
          }}
          maxWidth={'md'}
          className={classes.modificationCardSize}
          title={(viewMode || (franchiseeUser && ['LOB','COUNTRY','LOB+COUNTRY','STATE'].includes(ruleInfo?.hierarchyType) == true ))? "View Rule" : "Edit Rule"}
        >
          {ruleInfoCard()}
          {ruleData()}
          {actionButtons()}
        </RACModalCard>
      )}
    </>
  );
}
